import { call, put } from 'redux-saga/effects';

import {
  AUTH_FAILURE
} from '../constants/actionTypes';

const processRequest = function *(action, api, actionOk, actionErr) {
  try {
    const res = yield call(api, action.payload);

    let data = null;
    if (res.text && res.text.length > 0)
      data = JSON.parse(res.text);

    // publish positive scenarion actions
    if (Array.isArray(actionOk))
      for (const aok of actionOk)
        yield put({type: aok, payload: data});
    else
      yield put({type: actionOk, payload: data});

  } catch (e) {
    // publish negative scenarion actions
    if (Array.isArray(actionErr))
      for (const aor of actionErr)
        yield put({type: aor, payload: e});
    else
      yield put({type: actionErr, payload: e});
    // logout user if some service returns 401
    if (e.response && e.response.statusCode === 401)
      yield put({type: AUTH_FAILURE, payload: e});
  }
}

export { processRequest }

import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../common/Loader'

class OraclePhoneNumber extends React.PureComponent {
  render() {
    if (!this.props.loadingCurrentUserDetail && this.props.currentUserDetail) {
      return(
        <div className="col-12 col-md-6">
          <div className="phone">
            {this.props.currentUserDetail.primaryTelephoneNumber}
          </div>
        </div>
      );
    }
    else
      return(<Loader loading={this.props.loadingCurrentUserDetail}/>);
  }
}

const mapStateToProps = state => {
  return {
    currentUserDetail: state.usrdash.currentUserDetail,
    loadingCurrentUserDetail: state.usrdash.loadingCurrentUserDetail
  }
};

export default connect(mapStateToProps)(OraclePhoneNumber);

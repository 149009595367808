import { takeLatest, all } from 'redux-saga/effects';
import { processRequest } from './util';

import auditApi from '../api/audit';

import {
  GET_AUDIT,
  GET_AUDIT_OK,
  GET_AUDIT_ERR,
} from '../constants/actionTypes';

function* getAudit(action) {
  yield processRequest(
    action,
    auditApi.getAudit,
    GET_AUDIT_OK,
    GET_AUDIT_ERR
  );
}

function* auditApiService() {
  yield all([
    yield takeLatest(GET_AUDIT, getAudit),
  ]);
}

export default auditApiService

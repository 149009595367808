import React, {Suspense} from 'react';
import { HashRouter as Router, Route, Switch} from 'react-router-dom';
import PrivateRoute from 'react-private-route'
import { CookiesProvider } from 'react-cookie';
import { connect } from 'react-redux';

import Home from './Home';
import Login from './Login';

import {
  AUTHENTICATED_USER_LOAD
} from '../constants/actionTypes';

class App extends React.Component {

  componentDidMount() {
    this.props.loadAuthenticatedUser();
  }

  render() {
    const isAuth = !!this.props.isAuthenticated;

    return (
      <CookiesProvider>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path='/login' exact={true} component={(props) => <Login isAuthenticated={isAuth} {...props} />}/>
              <PrivateRoute path='/' component={(props) => <Home {...props} />} isAuthenticated={isAuth}/>
            </Switch>
          </Suspense>
        </Router>
      </CookiesProvider>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  loadAuthenticatedUser: () =>
    dispatch({ type: AUTHENTICATED_USER_LOAD })
});

const mapStateToProps = state => {
  return {
    isAuthenticated: state.common.isAuthenticated
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

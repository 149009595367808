import React from 'react';
import { connect } from 'react-redux';

import { useTable, usePagination } from 'react-table'

import Loader from '../common/Loader';
import BreadcrumbMenu from '../menu/BreadcrumbMenu';

import {
  PAGE_CHANGE,
  GET_AUDIT
} from '../../constants/actionTypes';

import {
  PAGE_AUDIT
} from '../../constants/pageTypes';

class AuditListView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      breadcrumbs: [
        {
          title: "Auditní záznamy",
          active: true
        }
      ],
      columns: [
        {
          Header: 'Datum události',
          accessor: 'stampFormatted'
        },
        {
          Header: 'Uživatel (email)',
          accessor: 'userEmail'
        },
        {
          Header: 'Uživatel (jméno)',
          accessor: 'userName'
        },
        {
          Header: 'Událost',
          accessor: 'detail'
        },
      ]
    };

    this.props.onPageChange(PAGE_AUDIT);
  }

  componentDidMount() {
    this.props.loadData();
  }

  render() {
    return (
      <main id="audit-log" className="col-sm-12 col-lg-9">
        <div className="box">
          <div className="clearfix">
            <BreadcrumbMenu crumbs={this.state.breadcrumbs}/>
          </div>
          <div>
            <p>Jsou zobrazeny auditní záznamy za posledních 14 dnů.</p>
            {this.props.loading ?
              <Loader loading={this.props.loading}/>
              : <Table columns={this.state.columns} data={this.props.auditList} />
            }
          </div>
        </div>
      </main>
    );
  }
}

function Table({ columns, data }) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  )


  return (
    <>
    <table {...getTableProps()} className="table table-striped table-sm bg-white" >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} scope="col">{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(
                (row, i) =>
                  prepareRow(row) || (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        )
                      })}
                    </tr>
                  )
              )}
            </tbody>
          </table>

          <div className="pagination align-items-center flex-wrap">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="page-link">
              {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage} className="page-link">
              {'<'}
            </button>{' '}
            <button onClick={() => nextPage()} disabled={!canNextPage} className="page-link">
              {'>'}
            </button>{' '}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="page-link">
              {'>>'}
            </button>{' '}
            <span className="d-inline pl-3 pr-3">
              Strana{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 40, 60, 80, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Zobraz {pageSize}
                </option>
              ))}
            </select>
          </div>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  onPageChange: payload =>
    dispatch({ type: PAGE_CHANGE, payload }),
  loadData: () =>
    dispatch({ type: GET_AUDIT })
});

const mapStateToProps = state => {
  return {
    loading: state.audit.loading,
    auditList: state.audit.auditList
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditListView);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';

const mapStateToProps = state => {
  return {
    currentPage: state.common.currentPage
  }
};

class MenuItem extends React.PureComponent {

  navItemClassName(forPage) {
    if (this.props.currentPage === forPage)
      return "nav-item nav-link active";
    else
      return "nav-item nav-link";
  }

  render() {
    return(
      <Link className={this.navItemClassName(this.props.itemPage)} to={this.props.to}>{this.props.children}</Link>
    );
  }
}

export default connect(mapStateToProps)(withRouter(MenuItem));

import request from 'superagent';

export default {

  getPersonasList: () => {
    const req = request
      .get('/api/adm/persona/list')
      .withCredentials();
    return req;
  },

  getPersonaUser: (payload) => {
    const req = request
      .get('/api/adm/persona/user/' + payload.id)
      .withCredentials();
    return req;
  },

  getPersonaOracle: (payload) => {
    const req = request
      .get('/api/adm/persona/oracle/' + payload.id)
      .withCredentials();
    return req;
  },

  getPersonaUserOraclesList: (payload) => {
    const req = request
      .get('/api/adm/persona/user/' + payload.id + '/oracles')
      .withCredentials();
    return req;
  },

  updatePersonaUser: (payload) => {
    const req = request
      .put('/api/adm/persona/user/' + payload.userId)
      .send(payload)
      .withCredentials();
    return req;
  },

  updatePersonaOracle: (payload) => {
    const req = request
      .put('/api/adm/persona/oracle/' + payload.oracleId)
      .send(payload)
      .withCredentials();
    return req;
  },

  deletePersonaOracle: (payload) => {
    const req = request
      .delete('/api/adm/persona/oracle/' + payload.oracleId)
      .withCredentials();
    return req;
  },

  deletePersonaUser: (payload) => {
    const req = request
      .delete('/api/adm/persona/user/' + payload.userId)
      .withCredentials();
    return req;
  },

  createPersonaUser: (payload) => {
    const req = request
      .post('/api/adm/persona/user/')
      .send(payload)
      .withCredentials();
    return req;
  },

  createPersonaOracle: (payload) => {
    const req = request
      .post('/api/adm/persona/oracle/')
      .send(payload)
      .withCredentials();
    return req;
  },

  getExchangesList: () => {
    const req = request
      .get('/api/adm/exchange')
    return req;
  },

  changePersonaPassword: (payload) => {
    const req = request
      .put('/api/adm/persona/user/' + payload.userId + '/password')
      .send(payload)
      .withCredentials();
    return req;
  },

  uploadOracleAvatar: (payload) => {
    const req = request
      .post('/api/adm/persona/oracle/' + payload.oracleId + '/avatar')
      .attach('file', payload.file)
      .withCredentials();
    return req;
  },

  uploadUserAvatar: (payload) => {
    const req = request
      .post('/api/adm/persona/user/' + payload.userId + '/avatar')
      .attach('file', payload.file)
      .withCredentials();
    return req;
  },

  getPagesForSelect: (payload) => {
    const req = request
      .get('/api/adm/persona/oracle/' + payload.id + '/pageselect')
      .withCredentials();
    return req;
  },

  getNumbersForSelect: (payload) => {
    const req = request
      .get('/api/adm/persona/oracle/' + payload.id + '/numselect')
      .withCredentials();
    return req;
  },

  assignNumberToOracle: (payload) => {
    const req = request
      .put('/api/adm/persona/oracle/' + payload.oracleId + '/numselect/' + payload.numberId)
      .withCredentials();
    return req;
  },

  removeNumberFromOracle: (payload) => {
    const req = request
      .delete('/api/adm/persona/oracle/' + payload.oracleId + '/numselect/' + payload.numberId)
      .withCredentials();
    return req;
  }
}

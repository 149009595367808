import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

class ScrollButton extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleScroll= this.handleScroll.bind(this);
    this.state = {
      showScrollButton: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  render() {
    let { showScrollButton } = this.state;

    return (
      showScrollButton ?
      <div className="button-up shadow" title="Nahoru" onClick={this.scrollToTop}>
        <FontAwesomeIcon icon={faChevronUp} size="2x" className="text-secondary chevron"/>
      </div>
      : <></>
    )
  }

  handleScroll() {
    if(window.pageYOffset > 100) {
      this.setState({
        showScrollButton: true
      });
    }
    else {
      this.setState({
        showScrollButton: false
      });
    }
  }

  scrollToTop() {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }
}

export default ScrollButton;

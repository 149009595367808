import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import Loader from '../../common/Loader';
import Messages from '../../common/Messages';
import BreadcrumbMenu from '../../menu/BreadcrumbMenu';

import NumbersTable from './NumbersTable';


import {
  GET_PERSONA_ORACLE
} from '../../../constants/actionTypes';

class PersonaOracleAddNumberView extends React.PureComponent {
  componentDidMount() {
    if (this.props.oracleDetail === null)
      this.props.getPersonaOracle({id: this.props.match.params.id});
  }

  render() {

    if (this.props.oracleLoading)
      return (
        <main id="user" className="col-sm-12 col-lg-9">
          <div><Loader loading={this.props.oracleLoading}/></div>
        </main>
      );

    if (this.props.redirectToDetail)
      return (<Redirect to={'/personas/oracle/' + this.props.match.params.id + '/detail'} />);

    const breadcrumbs = [
      {
        title: "Správa kartářek",
        to: '/personas'
      },
      {
        title: this.props.oracleDetail.name,
        to: '/personas/oracle/' + this.props.oracleDetail.oracleId + '/detail',
        label: this.props.oracleDetail.title
      },
      {
        title: 'Přidání telefonního čísla',
        active: true,
        label: this.props.oracleDetail.exchange.name
      },
    ];

    return(
      <React.Fragment>
        <main id="addoranum" className="col-sm-12 col-lg-9">
          <Messages/>
          <div>
            <div className="clearfix">
              <BreadcrumbMenu crumbs={breadcrumbs}/>
            </div>
            <div className="row">
              <div className="col-12">
                <NumbersTable/>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    oracleLoading: state.persona.loading,
    oracleDetail: state.persona.oracleDetail,
    redirectToDetail: state.persona.redirectToDetail
  }
};

const mapDispatchToProps = dispatch => ({
  getPersonaOracle: payload =>
    dispatch({ type: GET_PERSONA_ORACLE, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PersonaOracleAddNumberView));

import { takeLatest, all } from 'redux-saga/effects';
import { processRequest } from './util';

import userDashboardApi from '../api/usrdash';

import {
  GET_CURRENT_USER_DETAIL,
  GET_CURRENT_USER_DETAIL_OK,
  GET_CURRENT_USER_DETAIL_ERR,
  OWN_PASSWORD_CHANGE,
  OWN_PASSWORD_CHANGE_OK,
  OWN_PASSWORD_CHANGE_ERR,
  CHANGE_CURRENT_USER_ONLINE_STATUS,
  CHANGE_CURRENT_USER_ONLINE_STATUS_OK,
  CHANGE_CURRENT_USER_ONLINE_STATUS_ERR,
  CHANGE_CURRENT_USER_ONCALL_STATUS,
  CHANGE_CURRENT_USER_ONCALL_STATUS_OK,
  CHANGE_CURRENT_USER_ONCALL_STATUS_ERR,
  LOAD_CURRENT_USER_ORACLES,
  LOAD_CURRENT_USER_ORACLES_OK,
  LOAD_CURRENT_USER_ORACLES_ERR
} from '../constants/actionTypes';

function* getCurrentUserDetail(action) {
  yield processRequest(
    action,
    userDashboardApi.getCurrentUserDetail,
    GET_CURRENT_USER_DETAIL_OK,
    GET_CURRENT_USER_DETAIL_ERR
  );
}

function* changeCurrentUserPassword(action) {
  yield processRequest(
    action,
    userDashboardApi.changeCurrentUserPassword,
    OWN_PASSWORD_CHANGE_OK,
    OWN_PASSWORD_CHANGE_ERR
  );
}

function* changeCurrentUserOnlineStatus(action) {
  yield processRequest(
    action,
    userDashboardApi.changeCurrentUserOnlineStatus,
    CHANGE_CURRENT_USER_ONLINE_STATUS_OK,
    CHANGE_CURRENT_USER_ONLINE_STATUS_ERR
  );
}

function* changeCurrentUserOncallStatus(action) {
  yield processRequest(
    action,
    userDashboardApi.changeCurrentUserOncallStatus,
    CHANGE_CURRENT_USER_ONCALL_STATUS_OK,
    CHANGE_CURRENT_USER_ONCALL_STATUS_ERR
  );
}

function* getCurrentUserOracles(action) {
  yield processRequest(
    action,
    userDashboardApi.loadCurrentUserOracles,
    LOAD_CURRENT_USER_ORACLES_OK,
    LOAD_CURRENT_USER_ORACLES_ERR
  );
}

function* userDashboardApiService() {
  yield all([
    takeLatest(GET_CURRENT_USER_DETAIL, getCurrentUserDetail),
    takeLatest(OWN_PASSWORD_CHANGE, changeCurrentUserPassword),
    takeLatest(CHANGE_CURRENT_USER_ONLINE_STATUS, changeCurrentUserOnlineStatus),
    takeLatest(CHANGE_CURRENT_USER_ONCALL_STATUS, changeCurrentUserOncallStatus),
    takeLatest(LOAD_CURRENT_USER_ORACLES, getCurrentUserOracles)
  ]);
}

export default userDashboardApiService

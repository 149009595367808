import { takeLatest, all } from 'redux-saga/effects';
import { processRequest } from './util';

import personaApi from '../api/persona';

import {
  GET_PERSONA_LIST,
  GET_PERSONA_LIST_OK,
  GET_PERSONA_LIST_ERR,
  GET_PERSONA_USER,
  GET_PERSONA_USER_OK,
  GET_PERSONA_USER_ERR,
  UPDATE_PERSONA_USER,
  UPDATE_PERSONA_USER_OK,
  UPDATE_PERSONA_USER_ERR,
  UPDATE_PERSONA_ORACLE,
  UPDATE_PERSONA_ORACLE_OK,
  UPDATE_PERSONA_ORACLE_ERR,
  DELETE_PERSONA_ORACLE,
  DELETE_PERSONA_ORACLE_OK,
  DELETE_PERSONA_ORACLE_ERR,
  DELETE_PERSONA_USER,
  DELETE_PERSONA_USER_OK,
  DELETE_PERSONA_USER_ERR,
  CREATE_PERSONA_USER,
  CREATE_PERSONA_USER_OK,
  CREATE_PERSONA_USER_ERR,
  CREATE_PERSONA_ORACLE,
  CREATE_PERSONA_ORACLE_OK,
  CREATE_PERSONA_ORACLE_ERR,
  GET_PERSONA_USER_ORACLES_LIST,
  GET_PERSONA_USER_ORACLES_LIST_OK,
  GET_PERSONA_USER_ORACLES_LIST_ERR,
  GET_PERSONA_ORACLE,
  GET_PERSONA_ORACLE_OK,
  GET_PERSONA_ORACLE_ERR,
  GET_EXCHANGES_LIST,
  GET_EXCHANGES_LIST_OK,
  GET_EXCHANGES_LIST_ERR,
  PERSONA_USER_PASSWORD_CHANGE,
  PERSONA_USER_PASSWORD_CHANGE_OK,
  PERSONA_USER_PASSWORD_CHANGE_ERR,
  UPLOAD_ORACLE_AVATAR,
  UPLOAD_ORACLE_AVATAR_OK,
  UPLOAD_ORACLE_AVATAR_ERR,
  UPLOAD_USER_AVATAR,
  UPLOAD_USER_AVATAR_OK,
  UPLOAD_USER_AVATAR_ERR,
  FILTER_PAGES_FOR_SELECTION,
  FILTER_PAGES_FOR_SELECTION_OK,
  FILTER_PAGES_FOR_SELECTION_ERR,
  FILTER_NUMBERS_FOR_SELECTION,
  FILTER_NUMBERS_FOR_SELECTION_OK,
  FILTER_NUMBERS_FOR_SELECTION_ERR,
  ASSIGN_NUMBER_TO_ORACLE,
  ASSIGN_NUMBER_TO_ORACLE_OK,
  ASSIGN_NUMBER_TO_ORACLE_ERR,
  REMOVE_NUMBER_FROM_ORACLE,
  REMOVE_NUMBER_FROM_ORACLE_OK,
  REMOVE_NUMBER_FROM_ORACLE_ERR
} from '../constants/actionTypes';

function* getPersonaList(action) {
  yield processRequest(
    action,
    personaApi.getPersonasList,
    GET_PERSONA_LIST_OK,
    GET_PERSONA_LIST_ERR
  );
}

function* getPersonaUser(action) {
  yield processRequest(
    action,
    personaApi.getPersonaUser,
    GET_PERSONA_USER_OK,
    GET_PERSONA_USER_ERR
  );
}

function* getPersonaOracle(action) {
  yield processRequest(
    action,
    personaApi.getPersonaOracle,
    GET_PERSONA_ORACLE_OK,
    GET_PERSONA_ORACLE_ERR
  );
}

function* getPersonaUserOraclesList(action) {
  yield processRequest(
    action,
    personaApi.getPersonaUserOraclesList,
    GET_PERSONA_USER_ORACLES_LIST_OK,
    GET_PERSONA_USER_ORACLES_LIST_ERR
  );
}

function* updatePersonaUser(action) {
  yield processRequest(
    action,
    personaApi.updatePersonaUser,
    UPDATE_PERSONA_USER_OK,
    UPDATE_PERSONA_USER_ERR
  );
}

function* updatePersonaOracle(action) {
  yield processRequest(
    action,
    personaApi.updatePersonaOracle,
    UPDATE_PERSONA_ORACLE_OK,
    UPDATE_PERSONA_ORACLE_ERR
  );
}

function* deletePersonaOracle(action) {
  yield processRequest(
    action,
    personaApi.deletePersonaOracle,
    DELETE_PERSONA_ORACLE_OK,
    DELETE_PERSONA_ORACLE_ERR
  );
}

function* deletePersonaUser(action) {
  yield processRequest(
    action,
    personaApi.deletePersonaUser,
    [DELETE_PERSONA_USER_OK, GET_PERSONA_LIST],
    DELETE_PERSONA_USER_ERR
  );
}

function* createPersonaUser(action) {
  yield processRequest(
    action,
    personaApi.createPersonaUser,
    CREATE_PERSONA_USER_OK,
    CREATE_PERSONA_USER_ERR
  );
}

function* createPersonaOracle(action) {
  yield processRequest(
    action,
    personaApi.createPersonaOracle,
    CREATE_PERSONA_ORACLE_OK,
    CREATE_PERSONA_ORACLE_ERR
  );
}

function* getExchangesList(action) {
  yield processRequest(
    action,
    personaApi.getExchangesList,
    GET_EXCHANGES_LIST_OK,
    GET_EXCHANGES_LIST_ERR
  );
}

function* changeUserPassword(action) {
  yield processRequest(
    action,
    personaApi.changePersonaPassword,
    PERSONA_USER_PASSWORD_CHANGE_OK,
    PERSONA_USER_PASSWORD_CHANGE_ERR
  );
}

function* uploadOracleAvatar(action) {
  yield processRequest(
    action,
    personaApi.uploadOracleAvatar,
    UPLOAD_ORACLE_AVATAR_OK,
    UPLOAD_ORACLE_AVATAR_ERR
  );
}

function* uploadUserAvatar(action) {
  yield processRequest(
    action,
    personaApi.uploadUserAvatar,
    UPLOAD_USER_AVATAR_OK,
    UPLOAD_USER_AVATAR_ERR
  );
}

function* getPagesForSelect(action) {
  yield processRequest(
    action,
    personaApi.getPagesForSelect,
    FILTER_PAGES_FOR_SELECTION_OK,
    FILTER_PAGES_FOR_SELECTION_ERR
  );
}

function* getNumbersForSelect(action) {
  yield processRequest(
    action,
    personaApi.getNumbersForSelect,
    FILTER_NUMBERS_FOR_SELECTION_OK,
    FILTER_NUMBERS_FOR_SELECTION_ERR
  );
}

function* assignNumberToOracle(action) {
  yield processRequest(
    action,
    personaApi.assignNumberToOracle,
    ASSIGN_NUMBER_TO_ORACLE_OK,
    ASSIGN_NUMBER_TO_ORACLE_ERR
  );
}

function* removeNumberFromOracle(action) {
  yield processRequest(
    action,
    personaApi.removeNumberFromOracle,
    REMOVE_NUMBER_FROM_ORACLE_OK,
    REMOVE_NUMBER_FROM_ORACLE_ERR
  );
}

function* personaApiService() {
  yield all([
    takeLatest(GET_PERSONA_LIST, getPersonaList),
    takeLatest(GET_PERSONA_USER, getPersonaUser),
    takeLatest(GET_PERSONA_ORACLE, getPersonaOracle),
    takeLatest(GET_PERSONA_USER_ORACLES_LIST, getPersonaUserOraclesList),
    takeLatest(UPDATE_PERSONA_USER, updatePersonaUser),
    takeLatest(UPDATE_PERSONA_ORACLE, updatePersonaOracle),
    takeLatest(DELETE_PERSONA_ORACLE, deletePersonaOracle),
    takeLatest(DELETE_PERSONA_USER, deletePersonaUser),
    takeLatest(CREATE_PERSONA_USER, createPersonaUser),
    takeLatest(CREATE_PERSONA_ORACLE, createPersonaOracle),
    takeLatest(GET_EXCHANGES_LIST, getExchangesList),
    takeLatest(PERSONA_USER_PASSWORD_CHANGE, changeUserPassword),
    takeLatest(UPLOAD_ORACLE_AVATAR, uploadOracleAvatar),
    takeLatest(UPLOAD_USER_AVATAR, uploadUserAvatar),
    takeLatest(FILTER_PAGES_FOR_SELECTION, getPagesForSelect),
    takeLatest(FILTER_NUMBERS_FOR_SELECTION, getNumbersForSelect),
    takeLatest(ASSIGN_NUMBER_TO_ORACLE, assignNumberToOracle),
    takeLatest(REMOVE_NUMBER_FROM_ORACLE, removeNumberFromOracle),
  ]);
}

export default personaApiService

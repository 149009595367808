import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../common/Loader'

import {
  CHANGE_CURRENT_USER_ONLINE_STATUS
} from '../../../constants/actionTypes';

class OracleOnlineStatus extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  render() {

    if (!this.props.loadingCurrentUserDetail) {
      const online = this.props.onlineStatus ? this.props.onlineStatus === 'ONL' : true;
      const onlineStatusImg = online ? 'img/online-white.png' : 'img/warning-white.png';
      const onlineStatusLabel = online ? 'Online' : 'Offline';
      const onlineStatusLabelDetail = online ? 'online, volno pro hovory' : 'offline, nelze přijímat hovory';
      const onlineStatusButtonLabel = this.props.changingOnlineStatus ? 'Pracuji' : online ? 'Offline' : 'Online';
      const onlineStatusClass = online ? 'online' : 'offline';
      const hasOracles = this.props.currentUserDetail !== null && this.props.currentUserDetail.hasOraclesDefined;


      return(
        <div className={'col-12 col-md-' + this.props.size}>
          {hasOracles && <div className={onlineStatusClass}>
            <img src={onlineStatusImg} alt={onlineStatusLabel} title={onlineStatusLabel}/>
            <div>
              <span className="status-main">{onlineStatusLabel}</span>
              <br></br>
              <span className="status-detail">Současný stav: {onlineStatusLabelDetail}.</span>
            </div>
            <div>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="switch1" checked={online} disabled={this.props.changingOnlineStatus} onChange={this.handleStatusChange}/>
                <label className="custom-control-label" htmlFor="switch1"></label>
              </div>
              <span className="d-block label">{onlineStatusButtonLabel}</span>
            </div>
          </div>}
          {!hasOracles && <div><span>Nemáte přiřazené žádné kartářky.</span></div>}
        </div>
      );
    }
    else
      return(<Loader loading={this.props.loadingCurrentUserDetail}/>);
  }

  handleStatusChange(event) {
    event.stopPropagation();

    const val = this.props.onlineStatus === 'ONL' ? 'OFF' : 'ONL';
    this.props.changeOnlineStatus({onlineStatus: val});
  }
}

const mapDispatchToProps = dispatch => ({
  changeOnlineStatus: payload =>
    dispatch({ type: CHANGE_CURRENT_USER_ONLINE_STATUS, payload }),
});

const mapStateToProps = state => {
  return {
    onlineStatus: state.usrdash.onlineStatus,
    changingOnlineStatus: state.usrdash.changingOnlineStatus,
    currentUserDetail: state.usrdash.currentUserDetail,
    loadingCurrentUserDetail: state.usrdash.loadingCurrentUserDetail
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OracleOnlineStatus);

import React from 'react';
import PropTypes from 'prop-types';

import Loader from './Loader'

class DetailPhoto extends React.PureComponent {

  render() {
    const loading = this.props.loading || false;
    const mode = this.props.mode || 'edit';
    const fullSrc = this.props.xt ? this.props.src + '?xt='  +this.props.xt : this.props.src;

    if (mode === 'new') {
      return (
        <div className="photo-big">
          <img src={this.props.defaultSrc} alt={this.props.alt} className="photo-big"/>
        </div>
      );
    }
    else {
      if (loading) {
        return (
          <Loader loading={loading}/>
        );
      }
      else {
        return (
          <div className="photo-big">
            <img src={fullSrc} alt={this.props.alt} className="photo-big"/>
          </div>
        );
      }
    }
  }
}

DetailPhoto.propTypes = {
  src: PropTypes.string.isRequired,
  defaultSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  xt: PropTypes.string,
  loading: PropTypes.bool,
  mode: PropTypes.string,
};

export default DetailPhoto;

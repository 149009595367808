import React from 'react';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkSquareAlt, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

import PageOracleTable from './PageOracleTable';

import {
  SWITCH_PAGE_COLLAPSE_STATUS,
} from '../../../constants/actionTypes';

class PageDetail extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleSwitchPageCollapseStatus = this.handleSwitchPageCollapseStatus.bind(this);
  }

  render() {
    const page = this.props.page;
    const isPageCollapsed = this.props.collapsedPages.has(page.pageId) && this.props.collapsedPages.get(page.pageId) === true;

    return(
      <div className="web-detail">
        <div className="tabulka mt-0">
          <div className="row">
            <div href="" className="col-12 col-md-5 d-flex">
              <div className="photo show-hide d-flex" href="" onClick={this.handleSwitchPageCollapseStatus}>
                <div  className="ico-show-hide" >
                  {isPageCollapsed ? <FontAwesomeIcon icon={faAngleDown} size="1x" className="text-secondary"/> : <FontAwesomeIcon icon={faAngleUp} size="1x" className="text-secondary"/>}
                </div>
                <img src={'/api/adm/page/' + page.pageId + '/avatar'} title={page.name} alt={page.name}/>
              </div>
              <div className="name">
                <h2><Link to={'/sites/' + page.pageId + '/detail'} className="text-dark">{page.name}</Link></h2>
                <a href={page.url} target="_blank" rel="noopener noreferrer" title="Přejít na externí web">
                  <FontAwesomeIcon icon={faExternalLinkSquareAlt}/>&nbsp;
                  {page.domain}
                </a>
              </div>
            </div>
            <div className="col-12 col-md-5 offset-md-2 state">
              <div>
                <span className="grey-b">{page.oraclesTotal}</span><br/>Celkem
              </div>
              <div>
                <span className="grey-b">{page.oraclesOnline}</span><br/>Online
              </div>
              <div>
                <span className="grey-b">{page.oraclesFreeToCall}</span><br/>Volných
              </div>
            </div>
          </div>

          {!isPageCollapsed && <PageOracleTable page={page}/>}

        </div>
      </div>
    );
  }

  handleSwitchPageCollapseStatus(event) {
    event.stopPropagation();
    this.props.switchPageCollapseStatus({
      pageId: this.props.page.pageId,
    });
  }
}

const mapDispatchToProps = dispatch => ({
  switchPageCollapseStatus: payload =>
    dispatch({ type: SWITCH_PAGE_COLLAPSE_STATUS, payload })
});

const mapStateToProps = state => {
  return {
    changingPageOracleActive: state.admdash.changingPageOracleActive,
    changingPageOracleOverride: state.admdash.changingPageOracleOverride,
    collapsedPages: state.admdash.collapsedPages
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PageDetail);

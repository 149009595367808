import React from 'react';
import { connect } from 'react-redux';

import BreadcrumbMenu from '../../menu/BreadcrumbMenu';
import PageListHeader from './PageListHeader';
import PageTable from './PageTable';

import {
  PAGE_CHANGE,
  GET_PAGE_LIST
} from '../../../constants/actionTypes';

import {
  PAGE_PAGES_ADMIN
} from '../../../constants/pageTypes';

class PageListView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      breadcrumbs: [
        {
          title: "Správa webů",
          active: true
        }
      ]
    };

    this.props.onPageChange(PAGE_PAGES_ADMIN);
  }

  componentDidMount() {
    this.props.loadData();
  }

  render() {
    return (
      <main id="web-management" className="col-sm-12 col-lg-9">
        <div className="box">
          <div className="clearfix">
            <BreadcrumbMenu crumbs={this.state.breadcrumbs}/>
            <PageListHeader/>
          </div>
          <PageTable/>
        </div>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onPageChange: payload =>
    dispatch({ type: PAGE_CHANGE, payload }),
  loadData: () =>
    dispatch({ type: GET_PAGE_LIST }),
});

export default connect(null, mapDispatchToProps)(PageListView);

import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import validation from '../../common/Validation';

import { ROLE_NAMES } from '../../../constants/enums';

const matchesPassword1 = validation.matchesField('password1');

const inputClassName = (touched, error, readOnly) => (
  readOnly ? 'form-control-plaintext' :
    touched ? (error ? "form-control is-invalid" : "form-control is-valid") : "form-control"
)

const renderInputField = ({ input, label, type, readOnly, meta: { touched, error, warning } }) => (
  <div className="col-12 col-md-6">
    <label>{label}</label>
    <div>
      <input {...input} type={type}
        className={inputClassName(touched, error, readOnly)} readOnly={readOnly}/>
      {touched && error && <div className="invalid-feedback">{error}</div>}
    </div>
  </div>
)

class PersonaUserForm extends React.PureComponent {
  render() {
    return(
      <form onSubmit={this.props.handleSubmit}>
        <div className="form-row">
          <Field type="text" component={renderInputField}
            name="firstName" label="Jméno" validate={[validation.required]}
            readOnly={this.props.readOnly}/>
          <Field type="text" component={renderInputField}
            name="lastName" label="Příjmení" validate={[validation.required]}
            readOnly={this.props.readOnly}/>
          <Field type="text" component={renderInputField}
            name="primaryTelephoneNumber" label="Telefonní číslo (pracovní)" validate={[validation.required]}
            readOnly={this.props.readOnly}/>
          <Field type="text" component={renderInputField}
            name="privateTelephoneNumber" label="Telefonní číslo (soukromé)"
            readOnly={this.props.readOnly}/>
          <Field type="text" component={renderInputField}
            name="email" label="Email (přihlašovací jméno)"
            readOnly={this.props.readOnly} validate={[validation.required]}/>

          <div className="w-100"></div>

          { this.props.mode === 'new' &&
            <>
              <Field type="password" component={renderInputField}
                  name="password1" label="Heslo" validate={[validation.required]}/>
              <Field type="password" component={renderInputField}
                  name="password2" label="Heslo (pro kontrolu)" validate={[validation.required, matchesPassword1]}/>
            </>
          }

          <RoleSelect {...this.props}/>

          <FormButtons {...this.props}/>
        </div>
      </form>
    );
  }
}

class RoleSelect extends React.PureComponent {
  render() {
    return (
      <div className="col-12 col-md-6">
        <label htmlFor="selectRole">Role</label>
        {this.props.readOnly ?
          <input type="text" className="form-control-plaintext" value={ROLE_NAMES[this.props.initialValues.role]}/> :
          <Field component="select" className="form-control" name="role" disabled={this.props.readOnly} validate={[validation.required]}>
            <option value="">--- nezvoleno ---</option>
            {Object.keys(ROLE_NAMES).map((key,i) => {return (<option value={key}>{ROLE_NAMES[key]}</option>)})}
          </Field>
        }
      </div>
    );
  }
}

class FormButtons extends React.PureComponent {

  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
  }

  render() {
    const showSubmitButtons = this.props.mode === 'edit' || this.props.mode === 'new';

    return(
      <div className="col-12">
        { showSubmitButtons &&
          <>
            <button type="submit" className="btn btn-primary float-right" disabled={this.props.pristine || this.props.submitting}>
              Uložit
            </button>
            <button type="button" className="btn btn-secondary float-right mr-2" disabled={this.props.pristine || this.props.submitting} onClick={this.props.reset}>
              Původní hodnoty
            </button>
          </>
        }
        <Link to="/" className="btn btn-primary float-right mr-2" onClick={this.goBack}>Zpět</Link>
      </div>
    );
  }

  goBack(e) {
    e.preventDefault();
    this.props.history.goBack();
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.persona.userDetail
  }
};

export default connect(mapStateToProps)(reduxForm({form: 'userDetailForm'})(withRouter(PersonaUserForm)))

import { takeLatest, all } from 'redux-saga/effects';
import { processRequest } from './util';

import pageApi from '../api/page';

import {
  GET_PAGE_LIST,
  GET_PAGE_LIST_OK,
  GET_PAGE_LIST_ERR,
  SET_PAGE_ACTIVE,
  SET_PAGE_ACTIVE_OK,
  SET_PAGE_ACTIVE_ERR,
  GET_PAGE,
  GET_PAGE_OK,
  GET_PAGE_ERR,
  DELETE_PAGE,
  DELETE_PAGE_OK,
  DELETE_PAGE_ERR,
  CHANGE_PAGE_API_PASSWORD,
  CHANGE_PAGE_API_PASSWORD_OK,
  CHANGE_PAGE_API_PASSWORD_ERR,
  UPDATE_PAGE,
  UPDATE_PAGE_OK,
  UPDATE_PAGE_ERR,
  CREATE_PAGE,
  CREATE_PAGE_OK,
  CREATE_PAGE_ERR,
  GET_PAGE_ORACLES_AFTER_POSITION_CHANGE,
  GET_PAGE_ORACLES,
  GET_PAGE_ORACLES_OK,
  GET_PAGE_ORACLES_ERR,
  UPLOAD_PAGE_AVATAR,
  UPLOAD_PAGE_AVATAR_OK,
  UPLOAD_PAGE_AVATAR_ERR,
  FILTER_ORACLES_FOR_SELECTION,
  FILTER_ORACLES_FOR_SELECTION_OK,
  FILTER_ORACLES_FOR_SELECTION_ERR,
  ASSIGN_ORACLE_TO_PAGE,
  ASSIGN_ORACLE_TO_PAGE_OK,
  ASSIGN_ORACLE_TO_PAGE_ERR,
  REMOVE_ORACLE_FROM_PAGE,
  REMOVE_ORACLE_FROM_PAGE_OK,
  REMOVE_ORACLE_FROM_PAGE_ERR,
  MOVE_ORACLE_ON_PAGE_UP,
  MOVE_ORACLE_ON_PAGE_UP_OK,
  MOVE_ORACLE_ON_PAGE_UP_ERR,
  MOVE_ORACLE_ON_PAGE_DOWN,
  MOVE_ORACLE_ON_PAGE_DOWN_OK,
  MOVE_ORACLE_ON_PAGE_DOWN_ERR
} from '../constants/actionTypes';

function* getPageList(action) {
  yield processRequest(
    action,
    pageApi.getPageList,
    GET_PAGE_LIST_OK,
    GET_PAGE_LIST_ERR
  );
}

function* setPageActive(action) {
  yield processRequest(
    action,
    pageApi.setPageActive,
    SET_PAGE_ACTIVE_OK,
    SET_PAGE_ACTIVE_ERR
  );
}

function* getPage(action) {
  yield processRequest(
    action,
    pageApi.getPage,
    GET_PAGE_OK,
    GET_PAGE_ERR
  );
}

function* deletePage(action) {
  yield processRequest(
    action,
    pageApi.deletePage,
    DELETE_PAGE_OK,
    DELETE_PAGE_ERR
  );
}

function* changePageApiPassword(action) {
  yield processRequest(
    action,
    pageApi.updatePageApiPassword,
    CHANGE_PAGE_API_PASSWORD_OK,
    CHANGE_PAGE_API_PASSWORD_ERR
  );
}

function* updatePage(action) {
  yield processRequest(
    action,
    pageApi.updatePage,
    UPDATE_PAGE_OK,
    UPDATE_PAGE_ERR
  );
}

function* createPage(action) {
  yield processRequest(
    action,
    pageApi.createPage,
    CREATE_PAGE_OK,
    CREATE_PAGE_ERR
  );
}

function* getPageOracles(action) {
  yield processRequest(
    action,
    pageApi.getPageOracles,
    GET_PAGE_ORACLES_OK,
    GET_PAGE_ORACLES_ERR
  );
}

function* uploadPageAvatar(action) {
  yield processRequest(
    action,
    pageApi.uploadPageAvatar,
    UPLOAD_PAGE_AVATAR_OK,
    UPLOAD_PAGE_AVATAR_ERR
  );
}

function* filterOraclesForSelection(action) {
  yield processRequest(
    action,
    pageApi.filterOraclesForSelection,
    FILTER_ORACLES_FOR_SELECTION_OK,
    FILTER_ORACLES_FOR_SELECTION_ERR
  );
}

function* assignOracleToPage(action) {
  yield processRequest(
    action,
    pageApi.assignOracleToPage,
    ASSIGN_ORACLE_TO_PAGE_OK,
    ASSIGN_ORACLE_TO_PAGE_ERR
  );
}

function* removeOracleFromPage(action) {
  yield processRequest(
    action,
    pageApi.removeOracleFromPage,
    REMOVE_ORACLE_FROM_PAGE_OK,
    REMOVE_ORACLE_FROM_PAGE_ERR
  );
}

function* moveOracleUp(action) {
  yield processRequest(
    action,
    pageApi.moveOracleUp,
    [MOVE_ORACLE_ON_PAGE_UP_OK, GET_PAGE_ORACLES_AFTER_POSITION_CHANGE],
    MOVE_ORACLE_ON_PAGE_UP_ERR
  );
}

function* moveOracleDown(action) {
  yield processRequest(
    action,
    pageApi.moveOracleDown,
    [MOVE_ORACLE_ON_PAGE_DOWN_OK, GET_PAGE_ORACLES_AFTER_POSITION_CHANGE],
    MOVE_ORACLE_ON_PAGE_DOWN_ERR
  );
}

function* pageApiService() {
  yield all([
    yield takeLatest(GET_PAGE_LIST, getPageList),
    yield takeLatest(SET_PAGE_ACTIVE, setPageActive),
    yield takeLatest(GET_PAGE, getPage),
    yield takeLatest(DELETE_PAGE, deletePage),
    yield takeLatest(CHANGE_PAGE_API_PASSWORD, changePageApiPassword),
    yield takeLatest(UPDATE_PAGE, updatePage),
    yield takeLatest(CREATE_PAGE, createPage),
    yield takeLatest(GET_PAGE_ORACLES, getPageOracles),
    yield takeLatest(GET_PAGE_ORACLES_AFTER_POSITION_CHANGE, getPageOracles),
    yield takeLatest(UPLOAD_PAGE_AVATAR, uploadPageAvatar),
    yield takeLatest(FILTER_ORACLES_FOR_SELECTION, filterOraclesForSelection),
    yield takeLatest(ASSIGN_ORACLE_TO_PAGE, assignOracleToPage),
    yield takeLatest(REMOVE_ORACLE_FROM_PAGE, removeOracleFromPage),
    yield takeLatest(MOVE_ORACLE_ON_PAGE_UP, moveOracleUp),
    yield takeLatest(MOVE_ORACLE_ON_PAGE_DOWN, moveOracleDown)
  ]);
}

export default pageApiService

import React from 'react';

import { Field, reduxForm } from 'redux-form'

import validation from '../../common/Validation';

const matchesPassword1 = validation.matchesField('newPassword');

const inputClassName = (touched, error, readOnly) => (
  readOnly ? 'form-control-plaintext' :
    touched ? (error ? "form-control is-invalid" : "form-control is-valid") : "form-control"
)

const renderInputField = ({ input, label, type, readOnly, meta: { touched, error, warning } }) => (
  <div className="col-12">
    <label>{label}</label>
    <div>
      <input {...input} type={type}
        className={inputClassName(touched, error, readOnly)} readOnly={readOnly}/>
      {touched && error && <div className="invalid-feedback">{error}</div>}
    </div>
  </div>
)

class ChangePageApiPasswordDialog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      initialValues: {}
    }
  }

  render() {
    return(
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="pageApiPasswordChangeTitle">Změna hesla pro API</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={this.props.handleSubmit}>
              <div className="modal-body">
                <Field type="password" component={renderInputField} validate={[validation.required]}
                  name="newPassword" label="Nové heslo"/>
                <Field type="password" component={renderInputField} validate={[validation.required, matchesPassword1]}
                  name="newPasswordCheck" label="Nové heslo (pro kontrolu)"/>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Zrušit</button>
                <button type="submit" className="btn btn-primary" disabled={this.props.pristine || this.props.submitting}>Změnit heslo</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({form: 'pageApiPasswordChangeForm'})(ChangePageApiPasswordDialog);

import React from 'react';
import { connect } from 'react-redux';

import {
  CLEAR_MESSAGES
} from '../../constants/actionTypes';


class Messages extends React.PureComponent {

  constructor(props) {
    super(props);

    this.dispatchClearMessages = this.dispatchClearMessages.bind(this);
  }

  render() {
    if (this.props.messages.length === 0)
      return(null);

    return(
      <div>
        <button type="button" className="close pull-right" aria-label="Zavřít informační hlášení" onClick={this.props.clearMessages}>
          <span aria-hidden="true">&times;</span>
        </button>
        { this.props.messages.map((msg, i) => <MessageEntry message={msg} key={i}/>) }
      </div>
    );
  }

  componentDidMount() {
    if (this.props.messages.length === 0)
      return(null);

    this.timer = setInterval(this.dispatchClearMessages, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  dispatchClearMessages() {
    this.props.clearMessages();
    clearInterval(this.timer);
  }
}

class MessageEntry extends React.PureComponent {
  render() {
    if (this.props.message.type === 'info')
      return(
        <div><span className="badge badge-success mr-1">O</span>{this.props.message.content}</div>
      );
    else
      return(
        <div><span className="badge badge-danger mr-1">X</span>{this.props.message.content}</div>
      );
  }
}

const mapStateToProps = state => {
  return {
    messages: state.messages.messages
  }
};

const mapDispatchToProps = dispatch => ({
  clearMessages: () =>
    dispatch({ type: CLEAR_MESSAGES })
});

export default connect(mapStateToProps,mapDispatchToProps)(Messages);

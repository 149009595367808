import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faPowerOff } from '@fortawesome/free-solid-svg-icons'

import {
  LOGOUT
} from '../../constants/actionTypes';

class UserPortrait extends React.PureComponent {

  render() {
    let name = 'Neznámý Uživatel';
    if (this.props.currentUserDetail)
      name = this.props.currentUserDetail.firstName + ' ' + this.props.currentUserDetail.lastName;

    const isADM = this.props.authenticatedUser && this.props.authenticatedUser.authorities[0].authority === 'ADM';

    return (
      <div>
        <div className="nav-item nav-link d-none d-lg-block">
          <Link to="/" onClick={this.props.logOutUser} className=" d-flex flex-row align-items-center justify-content-center">
            <FontAwesomeIcon icon={faPowerOff} size="2x" className="text-secondary"/>
            <span className="pl-2">Odhlášení</span>
          </Link>
        </div>
        <div className="nav-item user">
          <div className="mx-auto text-center">
            <img src={'/api/usr/avatar' + (this.props.avatarPhotoUpdateDate ? '?xt=' + this.props.avatarPhotoUpdateDate : '')} alt={name} title={name} className="photo"/>
            <span className="d-block">{name}</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUserDetail: state.usrdash.currentUserDetail,
    avatarPhotoUpdateDate: state.persona.avatarPhotoUpdateDate
  }
};

const mapDispatchToProps = dispatch => ({
  logOutUser: () =>
    dispatch({ type: LOGOUT })
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPortrait);

import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import ConfirmDialog from '../../common/ConfirmDialog';
import Loader from '../../common/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'

import {
  GET_PAGE_ORACLES,
  REMOVE_ORACLE_FROM_PAGE,
  MOVE_ORACLE_ON_PAGE_UP,
  MOVE_ORACLE_ON_PAGE_DOWN
} from '../../../constants/actionTypes';

class PageOraclesTable extends React.PureComponent {

  componentDidMount() {
    if (this.props.pageDetail !== null)
      this.props.getPageOracles({id: this.props.pageDetail.pageId});
  }

  render() {

    if (this.props.pageDetail === null) {
      return (
        <Loader loading={this.props.pageDetail === null}/>
      )
    }

    if (this.props.loadingOraclesList) {
      return (
        <Loader loading={this.props.loadingOraclesList}/>
      )
    }

    return(
      <div className="tabulka">

        <div className="row header">
          <div className="col-4"><Link to={'/sites/' + this.props.pageDetail.pageId + '/addoracle'} className="text-success"><FontAwesomeIcon icon={faPlusSquare} size="lg"/></Link> Uživatel / Kartářka</div>
          <div className="col-3 text-left">Linka / Centrála</div>
          <div className="col-3 text-left">Čísla</div>
        </div>
        <div class="overlayWrapper">
          {
            this.props.pageOracles.length > 0 ?
            this.props.pageOracles.map((oracle, i) => { return (
              <PageOraclesTableRow entry={oracle} first={i === 0} last={i === this.props.pageOracles.length - 1} page={this.props.pageDetail} key={i}  {...this.props}/>
            )})
            : <div className="text-left p-3"><span className="badge badge-warning">Žádná data</span> Web nemá přiřazené žádné kartářky.</div>
          }
          {this.props.movingOracles && <div class="overlayContent"><Loader loading={true}/></div>}
        </div>
      </div>
    );
  }
}

class PageOraclesTableRow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleOracleRemove = this.handleOracleRemove.bind(this);
    this.moveOracleUp = this.moveOracleUp.bind(this);
    this.moveOracleDown = this.moveOracleDown.bind(this);
  }

  render() {
    const entry = this.props.entry;

    return(
      <div className="row">
        <div className="col-1 buttons">
          <span className="d-flex flex-column">
            <FontAwesomeIcon icon={faArrowUp} size="lg" className={this.props.first ? 'text-muted' : 'text-success'} onClick={!this.props.first ? this.moveOracleUp : undefined}/>
            <FontAwesomeIcon icon={faArrowDown} size="lg" className={this.props.last ? 'text-muted' : 'text-success'} onClick={!this.props.last ? this.moveOracleDown : undefined}/>
          </span>
        </div>
        <div className="col-11 col-md-3 d-flex">
          <div className="photo">
            <img src={'/api/adm/persona/oracle/'+entry.oracleId+'/avatar'} alt={entry.title} title={entry.name}/>
          </div>
          <div className="name">
            <Link to={'/personas/oracle/'+entry.oracleId+'/detail'} title="Detail kartářky">{entry.name}</Link><br/  >
            <span className="grey-light">{entry.title}</span>
          </div>
          <a href="/" className="d-block d-md-none ml-auto" data-toggle="modal" data-target={'#removeOracleFromPageDialog' + entry.oracleId}><img src="img/cross.png" alt="Odebrat kartářku z webu" title="Odebrat kartářku z webu"/></a>
        </div>
        <div className="col-12 col-md-3 text-left sm-p-l-25">
          Linka <span className="red d-contents">{entry.lineNumber}</span>
          <span className="badge badge-primary ml-2">{entry.exchange.name}</span>
        </div>
        <div className="col-12 col-md-3 text-left sm-p-l-25">
          {
            entry.phoneNumbers.map((number, i) => { return (
              <span key={i}>{number.phoneNumber}&nbsp;&nbsp;<span className="red d-contents">{number.tariffPrice} Kč</span><br/></span>
            )})
          }
        </div>
        <div className="d-none d-md-flex col-md-2 buttons">
          <a href="/" className="hide-user" data-toggle="modal" data-target={'#removeOracleFromPageDialog' + entry.oracleId}><img src="img/cross.png" alt="Odebrat kartářku z webu" title="Odebrat kartářku z webu"/></a>
        </div>
        <div className="w-100 my-2 d-md-none"></div>
        <ConfirmDialog id={'removeOracleFromPageDialog' + entry.oracleId} title="Odebrat kartářku ze stránky" handleSubmit={this.handleOracleRemove}>
          Opravdu chcete odebrat <strong>{entry.title} {entry.name}</strong> ze stránky <strong>{this.props.page.name}</strong>?<br/><br/>
          <small>Odebere se pouze vazba na stránku. Definice kartářky bude zachována.</small>
        </ConfirmDialog>
      </div>
    );
  }

  handleOracleRemove(event) {
    this.props.removeOracleFromPage({
      pageId: this.props.page.pageId,
      oracleId: this.props.entry.oracleId
    });
    $("#removeOracleFromPageDialog"+this.props.entry.oracleId).modal("hide");
  }

  moveOracleUp(event) {
    this.props.moveOracleUp({
      pageId: this.props.page.pageId,
      oracleId: this.props.entry.oracleId
    });
  }

  moveOracleDown(event) {
    this.props.moveOracleDown({
      pageId: this.props.page.pageId,
      oracleId: this.props.entry.oracleId
    });
  }
}

const mapStateToProps = state => {
  return {
    loadingOraclesList: state.page.loadingOraclesList,
    pageOracles: state.page.pageOracles,
    pageDetail: state.page.pageDetail,
    movingOracles: state.page.movingOracles
  }
};

const mapDispatchToProps = dispatch => ({
  getPageOracles: payload =>
    dispatch({ type: GET_PAGE_ORACLES, payload }),
  removeOracleFromPage: payload =>
    dispatch({ type: REMOVE_ORACLE_FROM_PAGE, payload }),
  moveOracleUp: payload =>
    dispatch({ type: MOVE_ORACLE_ON_PAGE_UP, payload }),
  moveOracleDown: payload =>
    dispatch({ type: MOVE_ORACLE_ON_PAGE_DOWN, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(PageOraclesTable);

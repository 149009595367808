import {
  GET_PAGES_SUMMARY,
  GET_PAGES_SUMMARY_OK,
  SET_PAGE_ORACLE_ACTIVE,
  SET_PAGE_ORACLE_ACTIVE_OK,
  SET_PAGE_ORACLE_ACTIVE_ERR,
  SET_PAGE_ORACLE_OVERRIDE,
  SET_PAGE_ORACLE_OVERRIDE_OK,
  SET_PAGE_ORACLE_OVERRIDE_ERR,
  SWITCH_PAGE_COLLAPSE_STATUS
} from '../constants/actionTypes';

const { Map, List } = require('immutable');

export default (state = {
  loadingPagesSummary: true,
  changingPageOracleActive: false,
  changingPageOracleOverride: false,
  pagesSummary: List([]),
  collapsedPages: Map({})
}, action) => {
  switch (action.type) {
    case GET_PAGES_SUMMARY:
      return {
        ...state,
        loadingPagesSummary: true
      };
    case GET_PAGES_SUMMARY_OK:
      var collapsedPagesInitial = Map(state.collapsedPages);
      // Collapse all pages if this is first load
      if (collapsedPagesInitial.size === 0) {
        action.payload.forEach((page) => {
            collapsedPagesInitial = collapsedPagesInitial.set(page.pageId, true);
        });
      }

      return {
        ...state,
        loadingPagesSummary: false,
        collapsedPages: collapsedPagesInitial,
        pagesSummary: List(action.payload)
      }

    case SET_PAGE_ORACLE_ACTIVE:
      return {
        ...state,
        changingPageOracleActive: true
      }
    case SET_PAGE_ORACLE_ACTIVE_OK:
      const newPagesSummary = List(state.pagesSummary);
      newPagesSummary.forEach((page) => {
        page.oracles.forEach((oracle) => {
          if (oracle.oracleId === action.payload.value1)
            oracle.onlineRaw = action.payload.value2;
        });
      });

      return {
        ...state,
        pagesSummary: newPagesSummary,
        changingPageOracleActive: false
      }
    case SET_PAGE_ORACLE_ACTIVE_ERR:
      return {
        ...state,
        changingPageOracleActive: false
      }

    case SET_PAGE_ORACLE_OVERRIDE:
      return {
        ...state,
        changingPageOracleOverride: true
      }
    case SET_PAGE_ORACLE_OVERRIDE_OK:
      const newPagesSummaryX = List(state.pagesSummary);
      newPagesSummaryX.forEach((page) => {
        if (page.pageId === action.payload.pageId) {
          page.oracles.forEach((oracle) => {
            if (oracle.oracleId === action.payload.oracleId) {
              oracle.statusOverridden = action.payload.active;
              oracle.statusOverriddenValue = action.payload.valueOverride;
              oracle.online = action.payload.online;
              oracle.oncall = action.payload.oncall;
            }
          });

          page.oraclesActive = action.payload.oraclesActive;
          page.oraclesOnline = action.payload.oraclesOnline;
          page.oraclesFreeToCall = action.payload.oraclesFreeToCall;
        }
      });

      return {
        ...state,
        pagesSummary: newPagesSummaryX,
        changingPageOracleOverride: false
      }
    case SET_PAGE_ORACLE_OVERRIDE_ERR:
      return {
        ...state,
        changingPageOracleOverride: false
      }

    case SWITCH_PAGE_COLLAPSE_STATUS:
      return {
        ...state,
        collapsedPages: state.collapsedPages.set(action.payload.pageId,
          state.collapsedPages.has(action.payload.pageId)? !state.collapsedPages.get(action.payload.pageId) : true)
      }

    default:
      return state;
  }
};

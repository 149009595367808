import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import ConfirmDialog from '../../common/ConfirmDialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons'

import {
  SET_PAGE_ACTIVE,
  DELETE_PAGE
} from '../../../constants/actionTypes';

class PageTableRow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handlePageActiveChange = this.handlePageActiveChange.bind(this);
    this.handlePageRemove = this.handlePageRemove.bind(this);
  }

  render() {
    const entry = this.props.entry;
    return(
      <div className="row">
        <div className="col-3 col-md-1 photo">
          <Link to={'/sites/' + entry.pageId + '/detail'}>
            <img src={'/api/adm/page/' + entry.pageId + '/avatar'} alt={entry.name} title={entry.name}/>
          </Link>
        </div>
        <div className="col-8 col-md-6 name">
          <Link to={'/sites/' + entry.pageId + '/detail'}><h2>{entry.name}</h2></Link>
          <a href={entry.url} target="_blank" rel="noopener noreferrer" title="Odejít ze Správy kartářek">
            <FontAwesomeIcon icon={faExternalLinkSquareAlt}/>&nbsp;
            {entry.domain}
          </a>
        </div>
        <div className="col-4 col-md-2">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id={'status-' + entry.pageId} disabled={this.props.changingPageActive} checked={entry.active} onChange={this.handlePageActiveChange}/>
            <label className="custom-control-label" htmlFor={'status-' + entry.pageId}></label>
          </div>
        </div>
        <div className="col-4 col-md-2 buttons">
          <Link to={'/sites/' + entry.pageId + '/edit'} className="hide"><img src="img/pencil.png" alt="Upravit" title="Upravit"/></Link>
          <a href="/" className="hide-user" data-toggle="modal" data-target={'#removePageDialog' + entry.pageId}><img src="img/cross.png" alt="Odstranit web" title="Odstranit web"/></a>
        </div>
        <ConfirmDialog id={'removePageDialog' + entry.pageId} title="Smazat web" handleSubmit={this.handlePageRemove}>
          Opravdu chcete trvale smatat web <strong>{entry.name}</strong>?<br/><br/>
        <small>Veškerá data webu budou smazána.</small>
        </ConfirmDialog>
      </div>
    );
  }

  handlePageRemove(event) {
    this.props.deletePage({
      id: this.props.entry.pageId
    });
    $("#removePageDialog" + this.props.entry.pageId).modal("hide");
  }

  handlePageActiveChange(event) {
    event.stopPropagation();

    this.props.setPageActive({
      pageId: this.props.entry.pageId,
      active: !this.props.entry.active
    });
  }
}

const mapDispatchToProps = dispatch => ({
  setPageActive: payload =>
    dispatch({ type: SET_PAGE_ACTIVE, payload }),
  deletePage: payload =>
    dispatch({ type: DELETE_PAGE, payload})
});

const mapStateToProps = state => {
  return {
    changingPageActive: state.page.changingPageActive
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTableRow);

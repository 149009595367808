import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../common/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'

import {
  FILTER_ORACLES_FOR_SELECTION,
  ASSIGN_ORACLE_TO_PAGE
} from '../../../constants/actionTypes';

class OraclesTable extends React.PureComponent {

  componentDidMount() {
    this.props.filterOraclesForSelection({
      pageId: this.props.pageId
    });
  }

  render() {
    if (this.props.loadingFilteredOracles)
      return (
          <div><Loader loading={this.props.loadingFilteredOracles}/></div>
      );

    return(
      <table className="table">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Kartářka</th>
            <th scope="col">Uživatel</th>
            <th scope="col">Ústředna</th>
            <th scope="col">Linka</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.filteredOracles.length > 0 ?
            this.props.filteredOracles.map((oracle, i) => { return (
              <OraclesTableRow key={i} oracle={oracle} {...this.props}/>
            )})
            : <tr><td className="text-left p-3" colSpan="5"><span className="badge badge-warning">Žádná data</span> Není k dispozici žádná kartářka, kterou by bylo možné přiřadit na web.</td></tr>
          }
        </tbody>
      </table>
    );
  }
}

class OraclesTableRow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.assignOracle = this.assignOracle.bind(this);
  }

  render() {
    return(
      <tr>
        <th scope="row">
          <button className="btn btn-outline-success btn-sm" disabled={this.props.assigningOracleToPage} onClick={this.assignOracle}><FontAwesomeIcon icon={faUserPlus} /></button>
        </th>
        <td>{this.props.oracle.oracleName} {this.props.oracle.tag && <span class="badge badge-secondary">{this.props.oracle.tag}</span>}</td>
        <td>{this.props.oracle.userName}</td>
        <td><span class="badge badge-primary">{this.props.oracle.exchangeName}</span></td>
        <td><span class="text-danger">{this.props.oracle.lineNumber}</span></td>
      </tr>
    );
  }

  assignOracle() {
    this.props.assignOracle({
      pageId: this.props.pageId,
      oracleId: this.props.oracle.oracleId
    });
  }
}

const mapStateToProps = state => {
  return {
    loadingFilteredOracles: state.page.loadingFilteredOracles,
    filteredOracles: state.page.filteredOracles,
    assigningOracleToPage: state.page.assigningOracleToPage
  }
};

const mapDispatchToProps = dispatch => ({
  filterOraclesForSelection: payload =>
    dispatch({ type: FILTER_ORACLES_FOR_SELECTION, payload }),
  assignOracle: payload =>
    dispatch({ type: ASSIGN_ORACLE_TO_PAGE, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(OraclesTable);

import {
  GET_PERSONA_LIST,
  GET_PERSONA_LIST_OK,
  GET_PERSONA_LIST_ERR,
  GET_PERSONA_USER,
  GET_PERSONA_USER_OK,
  GET_PERSONA_USER_ERR,
  GET_PERSONA_ORACLE,
  GET_PERSONA_ORACLE_OK,
  GET_PERSONA_ORACLE_ERR,
  GET_PERSONA_USER_ORACLES_LIST,
  GET_PERSONA_USER_ORACLES_LIST_OK,
  GET_PERSONA_USER_ORACLES_LIST_ERR,
  UPDATE_PERSONA_USER,
  UPDATE_PERSONA_USER_OK,
  UPDATE_PERSONA_USER_ERR,
  UPDATE_PERSONA_ORACLE,
  UPDATE_PERSONA_ORACLE_OK,
  UPDATE_PERSONA_ORACLE_ERR,
  DELETE_PERSONA_ORACLE,
  DELETE_PERSONA_ORACLE_ERR,
  DELETE_PERSONA_ORACLE_OK,
  DELETE_PERSONA_USER,
  DELETE_PERSONA_USER_OK,
  DELETE_PERSONA_USER_ERR,
  CREATE_PERSONA_USER,
  CREATE_PERSONA_USER_OK,
  CREATE_PERSONA_USER_ERR,
  CREATE_PERSONA_ORACLE,
  CREATE_PERSONA_ORACLE_OK,
  CREATE_PERSONA_ORACLE_ERR,
  GET_EXCHANGES_LIST,
  GET_EXCHANGES_LIST_OK,
  GET_EXCHANGES_LIST_ERR,
  UPLOAD_ORACLE_AVATAR_OK,
  UPLOAD_ORACLE_AVATAR,
  UPLOAD_ORACLE_AVATAR_ERR,
  UPLOAD_USER_AVATAR_OK,
  UPLOAD_USER_AVATAR,
  UPLOAD_USER_AVATAR_ERR,
  SET_PAGE_ORACLE_ACTIVE_OK,
  SET_PAGE_ORACLE_OVERRIDE_OK,
  FILTER_PAGES_FOR_SELECTION,
  FILTER_PAGES_FOR_SELECTION_OK,
  FILTER_PAGES_FOR_SELECTION_ERR,
  FILTER_NUMBERS_FOR_SELECTION,
  FILTER_NUMBERS_FOR_SELECTION_OK,
  FILTER_NUMBERS_FOR_SELECTION_ERR,
  ASSIGN_ORACLE_TO_PAGE_OK,
  ASSIGN_NUMBER_TO_ORACLE,
  ASSIGN_NUMBER_TO_ORACLE_OK,
  ASSIGN_NUMBER_TO_ORACLE_ERR,
  REMOVE_ORACLE_FROM_PAGE,
  REMOVE_NUMBER_FROM_ORACLE,
  REMOVE_NUMBER_FROM_ORACLE_OK,
  REMOVE_NUMBER_FROM_ORACLE_ERR,
  SWITCH_USER_COLLAPSE_STATUS
} from '../constants/actionTypes';

const { Map, List } = require('immutable');

export default (state = {
  loading: true,
  error: false,
  errorDetail: null,
  userDetail: null,
  oracleDetail: null,
  personasList: [],
  personasUsersCount: 0,
  personasOraclesCount: 0,
  redirectToList: false,
  redirectToDetail: false,
  loadingUserOraclesList: true,
  userOraclesList: [],
  exchangesList: [],
  loadingExchangesList: true,
  uploadingAvatarPhoto: false,
  pagesForSelectList: [],
  loadingPagesForSelect: false,
  numbersForSelectList: [],
  loadingNumbersForSelect: true,
  assigningNumberToOracle: false,
  removingOracle: false,
  removingUser: false,
  collapsedUsers: Map({})
}, action) => {
  switch (action.type) {

    case GET_PERSONA_LIST:
    case GET_PERSONA_USER:
    case GET_PERSONA_ORACLE:
    case UPDATE_PERSONA_USER:
    case UPDATE_PERSONA_ORACLE:
    case CREATE_PERSONA_USER:
    case CREATE_PERSONA_ORACLE:
      return {
        ...state,
        loading: true,
        personasList: [],
        userDetail: {},
        oracleDetail:{},
        redirectToList: false
      }

    case GET_EXCHANGES_LIST:
      return {
        ...state,
        loadingExchangesList: true,
        exchangesList: []
      }
    case GET_EXCHANGES_LIST_OK:
      return {
        ...state,
        loadingExchangesList: false,
        exchangesList: action.payload
      }
    case GET_EXCHANGES_LIST_ERR:
      return {
        ...state,
        loadingExchangesList: false,
        exchangesList: []
      }

    case GET_PERSONA_LIST_ERR:
    case GET_PERSONA_USER_ERR:
    case GET_PERSONA_ORACLE_ERR:
    case UPDATE_PERSONA_USER_ERR:
    case UPDATE_PERSONA_ORACLE_ERR:
    case CREATE_PERSONA_USER_ERR:
    case CREATE_PERSONA_ORACLE_ERR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetail: action.payload
      };
    case GET_PERSONA_LIST_OK:
      var collapsedUsersInitial = Map(state.collapsedUsers);
      // Collapse all pages if this is first load
      if (collapsedUsersInitial.size === 0) {
        action.payload.forEach((persona) => {
            if (persona.type === 'USER') {
              collapsedUsersInitial = collapsedUsersInitial.set(persona.id, true);
            }
        });
      }

      return {
        ...state,
        loading: false,
        error: false,
        personasList: action.payload,
        personasUsersCount: action.payload != null ? action.payload.filter(obj => obj.type === "USER").length : 0,
        personasOraclesCount: action.payload != null ? action.payload.filter(obj => obj.type === "ORACLE").length : 0,
        collapsedUsers: collapsedUsersInitial
      };
    case GET_PERSONA_USER_OK:
      return {
        ...state,
        userDetail: action.payload,
        loading: false,
        error: false,
      }
    case GET_PERSONA_ORACLE_OK:
      return {
        ...state,
        oracleDetail: action.payload,
        redirectToDetail: false,
        loading: false,
        error: false,
      }
    case UPDATE_PERSONA_USER_OK:
    case UPDATE_PERSONA_ORACLE_OK:
    case CREATE_PERSONA_USER_OK:
    case CREATE_PERSONA_ORACLE_OK:
      return {
        ...state,
        loading: false,
        error: false,
        redirectToList: true
      }

    case GET_PERSONA_USER_ORACLES_LIST:
      return {
        ...state,
        loadingUserOraclesList: true,
        userOraclesList: []
      }
    case GET_PERSONA_USER_ORACLES_LIST_ERR:
      return {
        ...state,
        loadingUserOraclesList: false,
        userOraclesList: [],
        error: true,
        errorDetail: action.payload
      }
    case GET_PERSONA_USER_ORACLES_LIST_OK:
      return {
        ...state,
        loadingUserOraclesList: false,
        userOraclesList: action.payload
      }

    case UPLOAD_ORACLE_AVATAR:
    case UPLOAD_USER_AVATAR:
      return {
        ...state,
        uploadingAvatarPhoto: true
      }

    case UPLOAD_ORACLE_AVATAR_OK:
    case UPLOAD_USER_AVATAR_OK:
      return {
        ...state,
        avatarPhotoUpdateDate: new Date().getTime(),
        uploadingAvatarPhoto: false
      }

    case UPLOAD_ORACLE_AVATAR_ERR:
    case UPLOAD_USER_AVATAR_ERR:
      return {
        ...state,
        uploadingAvatarPhoto: false
      }

    case DELETE_PERSONA_ORACLE:
      return {
        ...state,
        removingOracle: true
      }
    case DELETE_PERSONA_ORACLE_ERR:
      return {
        ...state,
        removingOracle: false
      }
    case DELETE_PERSONA_ORACLE_OK:
      const deletedPersonasList = [];
      state.personasList.forEach((usrOra) => {
        if (usrOra.type !== "ORACLE" || usrOra.id !== action.payload.value)
          deletedPersonasList.push(usrOra);
      });
      return {
        ...state,
        removingOracle: false,
        personasList: deletedPersonasList,
        personasOraclesCount: state.personasOraclesCount - 1
      }

    case DELETE_PERSONA_USER:
      return {
        ...state,
        removingUser: true
      }
    case DELETE_PERSONA_USER_OK:
      return {
        ...state,
        removingUser: false
      }
    case DELETE_PERSONA_USER_ERR:
      return {
        ...state,
        removingUser: false
      }

    case SET_PAGE_ORACLE_ACTIVE_OK:
      if (state.oracleDetail !== null) {
        const oracleDetailCopy = JSON.parse(JSON.stringify(state.oracleDetail)); // dirty way to make a deep copy
        oracleDetailCopy.onlineRaw = action.payload.value2;
        oracleDetailCopy.online = action.payload.value2;
        return {
          ...state,
          oracleDetail: oracleDetailCopy
        }
      }
      else {
        return {
          ...state
        }
      }

    case SET_PAGE_ORACLE_OVERRIDE_OK:
      if (state.oracleDetail !== null) {
        const oracleDetailCopy = JSON.parse(JSON.stringify(state.oracleDetail)); // dirty way to make a deep copy
        if (oracleDetailCopy.oracleAssignments) {
          oracleDetailCopy.oracleAssignments.forEach((asg) => {
            if (asg.id.pageId === action.payload.pageId) {
              asg.opStatusOverrideActive = action.payload.active;
              asg.opStatusOverrideValue = action.payload.valueOverride;
            }
          });
        }
        return {
          ...state,
          oracleDetail: oracleDetailCopy
        }
      }
      else {
        return {
          ...state
        }
      }

    case FILTER_PAGES_FOR_SELECTION:
      return {
        ...state,
        pagesForSelectList: [],
        loadingPagesForSelect: true,
        redirectToDetail: false
      }
    case FILTER_PAGES_FOR_SELECTION_OK:
      return {
        ...state,
        pagesForSelectList: action.payload,
        loadingPagesForSelect: false,
        redirectToDetail: false
      }
    case FILTER_PAGES_FOR_SELECTION_ERR:
      return {
        ...state,
        loadingPagesForSelect: false,
        redirectToDetail: false
      }

    case ASSIGN_NUMBER_TO_ORACLE:
      return {
        ...state,
        redirectToDetail: false,
        assigningNumberToOracle: true
      }

    case ASSIGN_NUMBER_TO_ORACLE_ERR:
      return {
        ...state,
        redirectToDetail: true,
        assigningNumberToOracle: false
      }

    case ASSIGN_ORACLE_TO_PAGE_OK:
    case ASSIGN_NUMBER_TO_ORACLE_OK:
      return {
        ...state,
        redirectToDetail: true,
        assigningNumberToOracle: false
      }

    case REMOVE_ORACLE_FROM_PAGE:
      if (state.oracleDetail !== null) {
        const oracleDetailCopy = JSON.parse(JSON.stringify(state.oracleDetail)); // dirty way to make a deep copy
        const newAssigments = [];
        if (oracleDetailCopy.oracleAssignments) {
          oracleDetailCopy.oracleAssignments.forEach((asg) => {
            if (asg.id.pageId !== action.payload.pageId) {
              newAssigments.push(asg);
            }
          });
          oracleDetailCopy.oracleAssignments = newAssigments;
        }
        return {
          ...state,
          oracleDetail: oracleDetailCopy
        }
      }
      else {
        return {
          ...state
        }
      }

    case FILTER_NUMBERS_FOR_SELECTION:
      return {
        ...state,
        numbersForSelectList: [],
        loadingNumbersForSelect: true,
        redirectToDetail: false
      }
    case FILTER_NUMBERS_FOR_SELECTION_OK:
      return {
        ...state,
        numbersForSelectList: action.payload,
        loadingNumbersForSelect: false,
        redirectToDetail: false
      }
    case FILTER_NUMBERS_FOR_SELECTION_ERR:
      return {
        ...state,
        loadingNumbersForSelect: false,
        redirectToDetail: false
      }

    case REMOVE_NUMBER_FROM_ORACLE_OK:
      if (state.oracleDetail !== null) {
        const oracleDetailCopy = JSON.parse(JSON.stringify(state.oracleDetail)); // dirty way to make a deep copy
        const newPhoneNumbers = [];
        oracleDetailCopy.phoneNumbers.forEach((pn) => {
          if (pn.phoneNumberId !== action.payload.value2) {
            newPhoneNumbers.push(pn);
          }
        });
        oracleDetailCopy.phoneNumbers = newPhoneNumbers;
        return {
          ...state,
          oracleDetail: oracleDetailCopy
        }
      }
      else {
        return {
          ...state
        }
      }

    case SWITCH_USER_COLLAPSE_STATUS:
        return {
          ...state,
          collapsedUsers: state.collapsedUsers.set(action.payload.userId,
            state.collapsedUsers.has(action.payload.userId)? !state.collapsedUsers.get(action.payload.userId) : true)
        }

    default:
      return state;
  }
};

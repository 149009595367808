import React from 'react';

class ConfirmDialog extends React.PureComponent {

  render() {
    return(
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{this.props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={this.props.handleSubmit}>
              <div className="modal-body">
                {this.props.children}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Zrušit</button>
                <button type="submit" className="btn btn-danger">Potvrdit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmDialog;

import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import validation from '../../common/Validation';

const inputClassName = (touched, error, readOnly) => (
  readOnly ? 'form-control-plaintext' :
    touched ? (error ? "form-control is-invalid" : "form-control is-valid") : "form-control"
)

const renderInputField = ({ input, label, type, readOnly, colClass, meta: { touched, error, warning } }) => (
  <div className={colClass}>
    <label>{label}</label>
    <div>
      <input {...input} type={type}
        className={inputClassName(touched, error, readOnly)} readOnly={readOnly}/>
      {touched && error && <div className="invalid-feedback">{error}</div>}
    </div>
  </div>
)

class PageForm extends React.PureComponent {
  render() {
    return(
      <form onSubmit={this.props.handleSubmit}>
        <div className="form-row">
          <Field type="text" component={renderInputField}
            name="name" label="Název" colClass="col-12 col-md-6"
            validate={[validation.required]}
            readOnly={this.props.readOnly}/>
          <Field type="text" component={renderInputField}
            name="listOrder" label="Pořadí v souhrnu" colClass="col-12 col-md-6"
            validate={[validation.isNumber]}
            readOnly={this.props.readOnly}/>
          <Field type="text" component={renderInputField}
            validate={[validation.required]}
            name="url" label="URL webové stránky" colClass="col-12"
            readOnly={this.props.readOnly}/>
          <Field type="text" component={renderInputField}
            name="apiUser" label="API uživatel" colClass="col-12 col-md-6"
            readOnly={this.props.readOnly}/>
          <Field type="password" component={renderInputField}
            name="apiPass" label={this.props.mode === 'new' ? "API heslo" : "Nové API heslo"} colClass="col-12 col-md-6"
            readOnly={this.props.readOnly}/>
          <FormButtons {...this.props}/>
        </div>
      </form>
    );
  }
}

class FormButtons extends React.PureComponent {
  render() {
    const showSubmitButtons = this.props.mode === 'edit' || this.props.mode === 'new';

    return(
      <div className="col-12 mt-4">
        { showSubmitButtons &&
          <>
            <button type="submit" className="btn btn-primary float-right" disabled={this.props.pristine || this.props.submitting}>
              Uložit
            </button>
            <button type="button" className="btn btn-secondary float-right mr-2" disabled={this.props.pristine || this.props.submitting} onClick={this.props.reset}>
              Původní hodnoty
            </button>
          </>
        }
        <Link to="/sites" className="btn btn-primary float-right mr-2">Zpět</Link>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.page.pageDetail
  }
};

export default connect(mapStateToProps)(reduxForm({form: 'pageDetailForm'})(PageForm));

import React from 'react';

class OracleCard extends React.PureComponent {

  render() {
    const oracle = this.props.oracle;

    return(
      <div className="web-detail tabulka mt-0">
        <div className="row">
          <div className="col-12 col-lg-4 photo">
            <img src={'/api/usr/oracle/' + oracle.oracleId + '/avatar'} width="90" height="90" alt={oracle.name} title={oracle.name}/>
            <div>
              <h2>{oracle.name}</h2>
              <span className="grey-light d-block mb-2">{oracle.title}</span>
              Linka <span className="red">{oracle.lineNumber}</span> {oracle.exchange.name  }
            </div>
          </div>
          <div className="col-12 col-lg-4">
            Na webech:
            <ul>
              {
                oracle.oracleAssignments.map((asg,i) => {
                  return (
                    <li key={i}><a href={asg.page.url}>{asg.page.domain}</a></li>
                  )
                })
              }
            </ul>
          </div>
          {oracle.phoneNumbers.length > 0 && <div className="col-12 col-lg-4 text-left">
            Telefonní čísla:
            <ul>
              {
                oracle.phoneNumbers.map((phone,i) => {
                  return (
                    <li key={i}>{phone.phoneNumber} <span className="red">{phone.tariffPrice} Kč</span></li>
                  )
                })
              }
            </ul>
          </div>}
        </div>
      </div>
    );
  }
}

export default OracleCard;

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const PAGE_CHANGE = 'PAGE_CHANGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_OK = 'LOGOUT_OK';
export const LOGOUT_ERR = 'LOGOUT_ERR';

export const AUTHENTICATED_USER_LOAD = 'AUTHENTICATED_USER_LOAD';
export const AUTHENTICATED_USER_LOAD_OK = 'AUTHENTICATED_USER_LOAD_OK';
export const AUTHENTICATED_USER_LOAD_ERR = 'AUTHENTICATED_USER_LOAD_ERR';

export const GET_PERSONA_LIST = 'GET_PERSONA_LIST';
export const GET_PERSONA_LIST_OK = 'GET_PERSONA_LIST_OK';
export const GET_PERSONA_LIST_ERR = 'GET_PERSONA_LIST_ERR';

export const GET_PERSONA_USER = 'GET_PERSONA_USER';
export const GET_PERSONA_USER_OK = 'GET_PERSONA_USER_OK';
export const GET_PERSONA_USER_ERR = 'GET_PERSONA_USER_ERR';

export const GET_PERSONA_ORACLE = 'GET_PERSONA_ORACLE';
export const GET_PERSONA_ORACLE_OK = 'GET_PERSONA_ORACLE_OK';
export const GET_PERSONA_ORACLE_ERR = 'GET_PERSONA_ORACLE_ERR';

export const GET_PERSONA_USER_ORACLES_LIST = 'GET_PERSONA_USER_ORACLES_LIST';
export const GET_PERSONA_USER_ORACLES_LIST_OK = 'GET_PERSONA_USER_ORACLES_LIST_OK';
export const GET_PERSONA_USER_ORACLES_LIST_ERR = 'GET_PERSONA_USER_ORACLES_LIST_ERR';

export const UPDATE_PERSONA_USER = 'UPDATE_PERSONA_USER';
export const UPDATE_PERSONA_USER_OK = 'UPDATE_PERSONA_USER_OK';
export const UPDATE_PERSONA_USER_ERR = 'UPDATE_PERSONA_USER_ERR';

export const UPDATE_PERSONA_ORACLE = 'UPDATE_PERSONA_ORACLE';
export const UPDATE_PERSONA_ORACLE_OK = 'UPDATE_PERSONA_ORACLE_OK';
export const UPDATE_PERSONA_ORACLE_ERR = 'UPDATE_PERSONA_ORACLE_ERR';

export const DELETE_PERSONA_ORACLE = 'DELETE_PERSONA_ORACLE';
export const DELETE_PERSONA_ORACLE_OK = 'DELETE_PERSONA_ORACLE_OK';
export const DELETE_PERSONA_ORACLE_ERR = 'DELETE_PERSONA_ORACLE_ERR';

export const DELETE_PERSONA_USER = 'DELETE_PERSONA_USER';
export const DELETE_PERSONA_USER_OK = 'DELETE_PERSONA_USER_OK';
export const DELETE_PERSONA_USER_ERR = 'DELETE_PERSONA_USER_ERR';

export const CREATE_PERSONA_USER = 'CREATE_PERSONA_USER';
export const CREATE_PERSONA_USER_OK = 'CREATE_PERSONA_USER_OK';
export const CREATE_PERSONA_USER_ERR = 'CREATE_PERSONA_USER_ERR';

export const CREATE_PERSONA_ORACLE = 'CREATE_PERSONA_ORACLE';
export const CREATE_PERSONA_ORACLE_OK = 'CREATE_PERSONA_ORACLE_OK';
export const CREATE_PERSONA_ORACLE_ERR = 'CREATE_PERSONA_ORACLE_ERR';

export const GET_PAGE_LIST = 'GET_PAGE_LIST';
export const GET_PAGE_LIST_OK = 'GET_PAGE_LIST_OK';
export const GET_PAGE_LIST_ERR = 'GET_PAGE_LIST_ERR';

export const GET_CURRENT_USER_DETAIL = 'GET_CURRENT_USER_DETAIL';
export const GET_CURRENT_USER_DETAIL_OK = 'GET_CURRENT_USER_DETAIL_OK';
export const GET_CURRENT_USER_DETAIL_ERR = 'GET_CURRENT_USER_DETAIL_ERR';

export const OWN_PASSWORD_CHANGE = 'OWN_PASSWORD_CHANGE';
export const OWN_PASSWORD_CHANGE_OK = 'OWN_PASSWORD_CHANGE_OK';
export const OWN_PASSWORD_CHANGE_ERR = 'OWN_PASSWORD_CHANGE_ERR';

export const PERSONA_USER_PASSWORD_CHANGE = 'PERSONA_USER_PASSWORD_CHANGE';
export const PERSONA_USER_PASSWORD_CHANGE_OK = 'PERSONA_USER_PASSWORD_CHANGE_OK';
export const PERSONA_USER_PASSWORD_CHANGE_ERR = 'PERSONA_USER_PASSWORD_CHANGE_ERR';

export const CHANGE_CURRENT_USER_ONLINE_STATUS = 'CHANGE_CURRENT_USER_ONLINE_STATUS';
export const CHANGE_CURRENT_USER_ONLINE_STATUS_OK = 'CHANGE_CURRENT_USER_ONLINE_STATUS_OK';
export const CHANGE_CURRENT_USER_ONLINE_STATUS_ERR = 'CHANGE_CURRENT_USER_ONLINE_STATUS_ERR';

export const CHANGE_CURRENT_USER_ONCALL_STATUS = 'CHANGE_CURRENT_USER_ONCALL_STATUS';
export const CHANGE_CURRENT_USER_ONCALL_STATUS_OK = 'CHANGE_CURRENT_USER_ONCALL_STATUS_OK';
export const CHANGE_CURRENT_USER_ONCALL_STATUS_ERR = 'CHANGE_CURRENT_USER_ONCALL_STATUS_ERR';

export const LOAD_CURRENT_USER_ORACLES = 'LOAD_CURRENT_USER_ORACLES';
export const LOAD_CURRENT_USER_ORACLES_OK = 'LOAD_CURRENT_USER_ORACLES_OK';
export const LOAD_CURRENT_USER_ORACLES_ERR = 'LOAD_CURRENT_USER_ORACLES_ERR';

export const GET_PAGES_SUMMARY = 'GET_PAGES_SUMMARY';
export const GET_PAGES_SUMMARY_OK = 'GET_PAGES_SUMMARY_OK';
export const GET_PAGES_SUMMARY_ERR = 'GET_PAGES_SUMMARY_ERR';

export const SET_PAGE_ACTIVE = 'SET_PAGE_ACTIVE';
export const SET_PAGE_ACTIVE_OK = 'SET_PAGE_ACTIVE_OK';
export const SET_PAGE_ACTIVE_ERR = 'SET_PAGE_ACTIVE_ERR';

export const GET_PAGE = 'GET_PAGE';
export const GET_PAGE_OK = 'GET_PAGE_OK';
export const GET_PAGE_ERR = 'GET_PAGE_ERR';

export const DELETE_PAGE = 'DELETE_PAGE';
export const DELETE_PAGE_OK = 'DELETE_PAGE_OK';
export const DELETE_PAGE_ERR = 'DELETE_PAGE_ERR';

export const CHANGE_PAGE_API_PASSWORD = 'CHANGE_PAGE_API_PASSWORD';
export const CHANGE_PAGE_API_PASSWORD_OK = 'CHANGE_PAGE_API_PASSWORD_OK';
export const CHANGE_PAGE_API_PASSWORD_ERR = 'CHANGE_PAGE_API_PASSWORD_ERR';

export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_PAGE_OK = 'UPDATE_PAGE_OK';
export const UPDATE_PAGE_ERR = 'UPDATE_PAGE_ERR';

export const CREATE_PAGE = 'CREATE_PAGE';
export const CREATE_PAGE_OK = 'CREATE_PAGE_OK';
export const CREATE_PAGE_ERR = 'CREATE_PAGE_ERR';

export const GET_PAGE_ORACLES = 'GET_PAGE_ORACLES';
export const GET_PAGE_ORACLES_AFTER_POSITION_CHANGE = 'GET_PAGE_ORACLES_AFTER_POSITION_CHANGE';
export const GET_PAGE_ORACLES_OK = 'GET_PAGE_ORACLES_OK';
export const GET_PAGE_ORACLES_ERR = 'GET_PAGE_ORACLES_ERR';

export const SET_PAGE_ORACLE_ACTIVE = 'SET_PAGE_ORACLE_ACTIVE';
export const SET_PAGE_ORACLE_ACTIVE_OK = 'SET_PAGE_ORACLE_ACTIVE_OK';
export const SET_PAGE_ORACLE_ACTIVE_ERR = 'SET_PAGE_ORACLE_ACTIVE_ERR';

export const SET_PAGE_ORACLE_OVERRIDE = 'SET_PAGE_ORACLE_OVERRIDE';
export const SET_PAGE_ORACLE_OVERRIDE_OK = 'SET_PAGE_ORACLE_OVERRIDE_OK';
export const SET_PAGE_ORACLE_OVERRIDE_ERR = 'SET_PAGE_ORACLE_OVERRIDE_ERR';

export const GET_EXCHANGES_LIST = 'GET_EXCHANGES_LIST';
export const GET_EXCHANGES_LIST_OK = 'GET_EXCHANGES_LIST_OK';
export const GET_EXCHANGES_LIST_ERR = 'GET_EXCHANGES_LIST_ERR';

export const UPLOAD_ORACLE_AVATAR = 'UPLOAD_ORACLE_AVATAR';
export const UPLOAD_ORACLE_AVATAR_OK = 'UPLOAD_ORACLE_AVATAR_OK';
export const UPLOAD_ORACLE_AVATAR_ERR = 'UPLOAD_ORACLE_AVATAR_ERR';

export const UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR';
export const UPLOAD_USER_AVATAR_OK = 'UPLOAD_USER_AVATAR_OK';
export const UPLOAD_USER_AVATAR_ERR = 'UPLOAD_USER_AVATAR_ERR';

export const UPLOAD_PAGE_AVATAR = 'UPLOAD_PAGE_AVATAR';
export const UPLOAD_PAGE_AVATAR_OK = 'UPLOAD_PAGE_AVATAR_OK';
export const UPLOAD_PAGE_AVATAR_ERR = 'UPLOAD_PAGE_AVATAR_ERR';

export const FILTER_ORACLES_FOR_SELECTION = 'FILTER_ORACLES_FOR_SELECTION';
export const FILTER_ORACLES_FOR_SELECTION_OK = 'FILTER_ORACLES_FOR_SELECTION_OK';
export const FILTER_ORACLES_FOR_SELECTION_ERR = 'FILTER_ORACLES_FOR_SELECTION_ERR';

export const ASSIGN_ORACLE_TO_PAGE = 'ASSIGN_ORACLE_TO_PAGE';
export const ASSIGN_ORACLE_TO_PAGE_OK = 'ASSIGN_ORACLE_TO_PAGE_OK';
export const ASSIGN_ORACLE_TO_PAGE_ERR = 'ASSIGN_ORACLE_TO_PAGE_ERR';

export const REMOVE_ORACLE_FROM_PAGE = 'REMOVE_ORACLE_FROM_PAGE';
export const REMOVE_ORACLE_FROM_PAGE_OK = 'REMOVE_ORACLE_FROM_PAGE_OK';
export const REMOVE_ORACLE_FROM_PAGE_ERR = 'REMOVE_ORACLE_FROM_PAGE_ERR';

export const GET_AUDIT = 'GET_AUDIT';
export const GET_AUDIT_OK = 'GET_AUDIT_OK';
export const GET_AUDIT_ERR = 'GET_AUDIT_ERR';

export const FILTER_PAGES_FOR_SELECTION = 'FILTER_PAGES_FOR_SELECTION';
export const FILTER_PAGES_FOR_SELECTION_OK = 'FILTER_PAGES_FOR_SELECTION_OK';
export const FILTER_PAGES_FOR_SELECTION_ERR = 'FILTER_PAGES_FOR_SELECTION_ERR';

export const FILTER_NUMBERS_FOR_SELECTION = 'FILTER_NUMBERS_FOR_SELECTION';
export const FILTER_NUMBERS_FOR_SELECTION_OK = 'FILTER_NUMBERS_FOR_SELECTION_OK';
export const FILTER_NUMBERS_FOR_SELECTION_ERR = 'FILTER_NUMBERS_FOR_SELECTION_ERR';

export const ASSIGN_NUMBER_TO_ORACLE = 'ASSIGN_NUMBER_TO_ORACLE';
export const ASSIGN_NUMBER_TO_ORACLE_OK = 'ASSIGN_NUMBER_TO_ORACLE_OK';
export const ASSIGN_NUMBER_TO_ORACLE_ERR = 'ASSIGN_NUMBER_TO_ORACLE_ERR';

export const REMOVE_NUMBER_FROM_ORACLE = 'REMOVE_NUMBER_FROM_ORACLE';
export const REMOVE_NUMBER_FROM_ORACLE_OK = 'REMOVE_NUMBER_FROM_ORACLE_OK';
export const REMOVE_NUMBER_FROM_ORACLE_ERR = 'REMOVE_NUMBER_FROM_ORACLE_ERR';

export const MOVE_ORACLE_ON_PAGE_UP = 'MOVE_ORACLE_ON_PAGE_UP';
export const MOVE_ORACLE_ON_PAGE_UP_OK = 'MOVE_ORACLE_ON_PAGE_UP_OK';
export const MOVE_ORACLE_ON_PAGE_UP_ERR = 'MOVE_ORACLE_ON_PAGE_UP_ERR';

export const MOVE_ORACLE_ON_PAGE_DOWN = 'MOVE_ORACLE_ON_PAGE_DOWN';
export const MOVE_ORACLE_ON_PAGE_DOWN_OK = 'MOVE_ORACLE_ON_PAGE_DOWN_OK';
export const MOVE_ORACLE_ON_PAGE_DOWN_ERR = 'MOVE_ORACLE_ON_PAGE_DOWN_ERR';

export const SWITCH_PAGE_COLLAPSE_STATUS = 'SWITCH_PAGE_COLLAPSE_STATUS';
export const SWITCH_USER_COLLAPSE_STATUS = 'SWITCH_USER_COLLAPSE_STATUS';

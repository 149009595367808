import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'react-router-redux'
import createHistory from 'history/createBrowserHistory';
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducer';
import rootSaga from  './saga';

export const history = createHistory();
const myRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware()

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production')
    return applyMiddleware(myRouterMiddleware, sagaMiddleware);
  else
    return applyMiddleware(myRouterMiddleware, sagaMiddleware, createLogger())
};

const store = createStore(
  rootReducer,
  composeWithDevTools(getMiddleware())
);

sagaMiddleware.run(rootSaga);

export {store};

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Loader from '../../common/Loader';
import Messages from '../../common/Messages';
import BreadcrumbMenu from '../../menu/BreadcrumbMenu';

import OracleFilter from './OracleFilter';
import OraclesTable from './OraclesTable';

import {
  GET_PAGE
} from '../../../constants/actionTypes';

class PageAddOracleView extends React.PureComponent {
  componentDidMount() {
    this.props.getPage({id: this.props.match.params.id});
  }

  render() {
    if (this.props.loading)
      return (
        <main id="web" className="col-sm-12 col-lg-9">
          <div><Loader loading={this.props.loading}/></div>
        </main>
      );

    const breadcrumbs = [
      {
        title: "Správa webů",
        to: '/sites'
      },
      {
        title: this.props.pageDetail.name,
        to: '/sites/' + this.props.pageDetail.pageId + '/detail',
        label: 'Stránka'
      },
      {
        title: 'Přidání kartářky',
        active: true
      },
    ];

    if (this.props.redirectToDetail)
      return (<Redirect to={'/sites/' + this.props.pageDetail.pageId + '/detail'} />);

    return(
      <React.Fragment>
        <main id="web" className="col-sm-12 col-lg-9">
          <Messages/>
          <div>
            <div className="clearfix">
              <BreadcrumbMenu crumbs={breadcrumbs}/>
            </div>
            <div className="row">
              <div className="col-12">
                {/*<OracleFilter/>*/}
                <OraclesTable pageId={this.props.pageDetail.pageId}/>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.page.loading,
    pageDetail: state.page.pageDetail,
    redirectToDetail: state.page.redirectToDetail
  }
};

const mapDispatchToProps = dispatch => ({
  getPage: payload =>
    dispatch({ type: GET_PAGE, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(PageAddOracleView);

import { combineReducers } from 'redux';
import common from './reducers/common';
import persona from './reducers/persona';
import page from './reducers/page'
import audit from './reducers/audit'
import usrdash from './reducers/usrdash'
import admdash from './reducers/admdash'
import messages from './reducers/messages'
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'

export default combineReducers({
  common,
  persona,
  page,
  usrdash,
  admdash,
  messages,
  audit,
  router: routerReducer,
  form: formReducer
});

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loader from '../../common/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import {
  FILTER_PAGES_FOR_SELECTION,
  ASSIGN_ORACLE_TO_PAGE
} from '../../../constants/actionTypes';

class PagesTable extends React.PureComponent {

  componentDidMount() {
    this.props.getPagesForSelect({
      id: this.props.match.params.id
    });
  }

  render() {
    if (this.props.loadingPagesForSelect)
      return (
          <div><Loader loading={this.props.loadingPagesForSelect}/></div>
      );

    return(
      <table className="table bg-white">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Web</th>
            <th scope="col">Doména</th>
            <th scope="col">URL</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.pagesForSelectList.length > 0 ?
            this.props.pagesForSelectList.map((page, i) => { return (
              <PagesTableRow key={i} page={page} {...this.props}/>
            )})
            : <tr><td className="text-left p-3" colSpan="4"><span className="badge badge-warning">Žádná data</span> Není k dispozici žádný aktivní web, na který by bylo možné kartářku přiřadit.</td></tr>
          }
        </tbody>
      </table>
    );
  }
}

class PagesTableRow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.assignOracle = this.assignOracle.bind(this);
  }

  render() {
    return(
      <tr>
        <th scope="row">
          <button className="btn btn-outline-success btn-sm" disabled={this.props.assigningOracleToPage} onClick={this.assignOracle}><FontAwesomeIcon icon={faPlus} /></button>
        </th>
        <td>{this.props.page.name}</td>
        <td>{this.props.page.domain}</td>
        <td>{this.props.page.url}</td>
      </tr>
    );
  }

  assignOracle() {
    this.props.assignOracle({
      pageId: this.props.page.pageId,
      oracleId: this.props.match.params.id
    });
  }
}

const mapStateToProps = state => {
  return {
    loadingPagesForSelect: state.persona.loadingPagesForSelect,
    pagesForSelectList: state.persona.pagesForSelectList,
    assigningOracleToPage: state.page.assigningOracleToPage
  }
};

const mapDispatchToProps = dispatch => ({
  getPagesForSelect: payload =>
    dispatch({ type: FILTER_PAGES_FOR_SELECTION, payload }),
  assignOracle: payload =>
    dispatch({ type: ASSIGN_ORACLE_TO_PAGE, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PagesTable));

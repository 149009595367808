import React from 'react';
import { connect } from 'react-redux';

import BreadcrumbMenu from '../../menu/BreadcrumbMenu';
import PersonaListHeader from './PersonaListHeader';
import PersonaTable from './PersonaTable';
import Messages from '../../common/Messages';

import {
  PAGE_CHANGE,
  GET_PERSONA_LIST
} from '../../../constants/actionTypes';

import {
  PAGE_PERSONAS_ADMIN
} from '../../../constants/pageTypes';

class PersonaListView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      breadcrumbs: [
        {
          title: "Správa kartářek",
          active: true
        }
      ]
    };

    this.props.onPageChange(PAGE_PERSONAS_ADMIN);
  }

  componentDidMount() {
    this.props.loadData();
  }

  render() {
    return (
      <main id="persona-management" className="col-sm-12 col-lg-9">
        <Messages/>
        <div>
          <div className="clearfix">
            <BreadcrumbMenu crumbs={this.state.breadcrumbs}/>
            <PersonaListHeader/>
          </div>
          <PersonaTable/>
        </div>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onPageChange: payload =>
    dispatch({ type: PAGE_CHANGE, payload }),
  loadData: () =>
    dispatch({ type: GET_PERSONA_LIST })
});

export default connect(null, mapDispatchToProps)(PersonaListView);

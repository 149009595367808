import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  SET_PAGE_ORACLE_ACTIVE,
  SET_PAGE_ORACLE_OVERRIDE
} from '../../../constants/actionTypes';

class PageOracleTableRow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleOracleActiveChange = this.handleOracleActiveChange.bind(this);
    this.handleOracleStatusOverrideValueChange = this.handleOracleStatusOverrideValueChange.bind(this);
    this.handleOracleStatusOverrideChange = this.handleOracleStatusOverrideChange.bind(this);
  }

  render() {
    const oracle = this.props.oracle;

    const overrideButtonLabel = oracle.statusOverridden ? 'Uvolnit' : 'Přepsat';
    const deactivateButtonLabel = oracle.online ? 'Deaktivovat' : 'Aktivovat';

    const onlineIco = oracle.online ? 'img/online-green.png' : 'img/inactive.png';
    const oncallIco = oracle.oncall ? 'img/occupied.png' : onlineIco;
    const statusIco = oracle.onlineRaw ? oncallIco : 'img/inactive.png';

    const onlineLabel = oracle.online ? 'Online' : 'Offline';
    const oncallLabel = oracle.oncall ? 'Obsazeno' : onlineLabel;
    const statusLabel = oracle.onlineRaw ? oncallLabel : 'Neaktivní';

    const rowClass = oracle.onlineRaw ? (oracle.oncall ? 'row kartarka occupied' : 'row kartarka online') : 'row kartarka inactive';

    return(
      <div className={rowClass}>
        <div className="col-12 col-md-4 d-flex">
          <div className="photo ml-4">
            <img src={'/api/adm/persona/oracle/' + oracle.oracleId + '/avatar'} alt={oracle.name} title={oracle.name}/>
          </div>
          <div className="name">
            <Link to={'/personas/oracle/' + oracle.oracleId + '/detail'} title="Detail kartářky">{oracle.name} {oracle.tag && <span className="badge badge-secondary">{oracle.tag}</span>}</Link><br/>{oracle.title}
          </div>
        </div>
        <div className="col-12 col-md-3 state">
          <div>
            <span className="grey-b">{oracle.lineNumber}</span><br/>{oracle.exchangeName}
          </div>
          <div>
            <img src={statusIco} alt={statusLabel} title={statusLabel}/><br/>{statusLabel}
          </div>
        </div>

        <div className="col-12 col-md-5 switches">
          <div className="form-group hide">
            <select id={'status-' + oracle.pageId + '-' + oracle.oracleId} className="form-control form-control-sm" value={oracle.statusOverriddenValue ? oracle.statusOverriddenValue : ''} disabled={this.props.changingPageOracleOverride} onChange={this.handleOracleStatusOverrideValueChange}>
              <option value="">--- nezvoleno ---</option>
              <option value="OFF">Offline</option>
              <option value="ONC">Obsazeno</option>
            </select>
            <label htmlFor={'status-' + oracle.pageId + '-' + oracle.oracleId} className="mt-1">Přepsat stavem</label>
          </div>
          <div>
            <div className="custom-control custom-switch hide">
              <input type="checkbox" className="custom-control-input" id={'statusOver-' + oracle.pageId + '-' + oracle.oracleId} disabled={this.props.changingPageOracleOverride} checked={oracle.statusOverridden} onChange={this.handleOracleStatusOverrideChange} />
              <label className="custom-control-label" htmlFor={'statusOver-' + oracle.pageId + '-' + oracle.oracleId}></label>
            </div>
            <span className="d-block hide">{overrideButtonLabel}</span>
          </div>
          <div>
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id={'deactive-' + oracle.pageId + '-' + oracle.oracleId} disabled={this.props.changingPageOracleActive} checked={oracle.onlineRaw} onChange={this.handleOracleActiveChange}/>
              <label className="custom-control-label" htmlFor={'deactive-' + oracle.pageId + '-' + oracle.oracleId}></label>
            </div>
            <span className="d-block">{deactivateButtonLabel}</span>
          </div>
        </div>


      </div>
    );
  }

  handleOracleStatusOverrideValueChange(event) {
    event.stopPropagation();

    this.props.setPageOracleOverride(
      {
        pageId: this.props.oracle.pageId,
        oracleId: this.props.oracle.oracleId,
        active: event.target.value !== '',
        valueOverride: event.target.value !== '' ? event.target.value : 'ONC'
      }
    );
  }

  handleOracleStatusOverrideChange(event) {
    event.stopPropagation();

    this.props.setPageOracleOverride(
      {
        pageId: this.props.oracle.pageId,
        oracleId: this.props.oracle.oracleId,
        active: !this.props.oracle.statusOverridden,
        valueOverride: 'ONC'
      }
    );
  }

  handleOracleActiveChange(event) {
    event.stopPropagation();
    this.props.setPageOracleActive({
      pageId: this.props.oracle.pageId,
      oracleId: this.props.oracle.oracleId,
      active: !this.props.oracle.onlineRaw
    });
  }
}

const mapDispatchToProps = dispatch => ({
  setPageOracleActive: payload =>
    dispatch({ type: SET_PAGE_ORACLE_ACTIVE, payload }),
  setPageOracleOverride: payload =>
    dispatch({ type: SET_PAGE_ORACLE_OVERRIDE, payload})
});

const mapStateToProps = state => {
  return {
    changingPageOracleActive: state.admdash.changingPageOracleActive,
    changingPageOracleOverride: state.admdash.changingPageOracleOverride
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PageOracleTableRow);

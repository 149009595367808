import {
  AUTHENTICATED_USER_LOAD,
  AUTHENTICATED_USER_LOAD_OK,
  AUTHENTICATED_USER_LOAD_ERR,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  PAGE_CHANGE,
  LOGOUT_OK
} from '../constants/actionTypes';

export default (state = {
  isAuthenticated: false,
  authenticatedUser: null
}, action) => {
  switch (action.type) {
    case AUTHENTICATED_USER_LOAD:
      return {
        ...state,
        authenticatedUser: null,
        isAuthenticated: false
      };

    case AUTHENTICATED_USER_LOAD_OK:
      return {
        ...state,
        authenticatedUser: action.payload ? action.payload : null
      };
    case AUTHENTICATED_USER_LOAD_ERR:
      return {
        ...state,
        authenticatedUser: null,
        isAuthenticated: false
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };

    case AUTH_FAILURE:
    case LOGOUT_OK:
    return {
      ...state,
      authenticatedUser: null,
      isAuthenticated: false
    };

    case PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload
      };
    default:
      return state;
  }
};

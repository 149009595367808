import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from '../../common/Loader';
import PersonaTableRow from './PersonaTableRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'

class PersonaTable extends React.PureComponent {

  render() {

    if (this.props.loading)
      return (
        <Loader loading={this.props.loading}/>
      );

    return (
      <div className="tabulka">
        <div className="row header">
          <div className="col-5 col-md-4"><Link to='/personas/user/0/new' className="text-success" title="Nový uživatel"><FontAwesomeIcon icon={faPlusSquare} size="lg"/></Link> Uživatel / Kartářka</div>
          <div className="col-4 col-md-3 text-left">Přihlášení</div>
          <div className="col-3 text-left">Email / Telefon</div>
        </div>
        <div class="overlayWrapper">
          {
            this.props.personasList.map((persona, i) => { return (
              <PersonaTableRow entry={persona} key={i}/>) }
            )
          }
          {(this.props.removingOracle || this.props.removingUser)  && <div class="overlayContent"><Loader loading={true}/></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.persona.loading,
    personasList: state.persona.personasList,
    removingOracle: state.persona.removingOracle,
    removingUser: state.persona.removingUser
  }
};

export default connect(mapStateToProps)(PersonaTable);

import { all,fork } from 'redux-saga/effects';

import personaApiService from './sagas/persona';
import commonApiService from './sagas/common';
import pageApiService from './sagas/page';
import userDashboardApiService from './sagas/usrdash'
import adminDashboardApiService from './sagas/admdash'
import auditApiService from './sagas/audit';

export default function* root() {
  yield all([
    fork(personaApiService),
    fork(commonApiService),
    fork(pageApiService),
    fork(userDashboardApiService),
    fork(adminDashboardApiService),
    fork(auditApiService),
  ]);
}

import React from 'react';
import {withRouter, Redirect} from 'react-router-dom';
import LoginError from './LoginError';

class Login extends React.Component {
  render() {
    const isAuthenticated = this.props.isAuthenticated;
    const redirectTo = this.props.location.state && this.props.location.state.from ? this.props.location.state.from.pathname : '/';

    if (isAuthenticated)
      return ( <Redirect to={redirectTo} />);
    else
      return (
        <main id="sign-in">
          <form action="/login-endpoint" method="post">
            <h1>Správa kartářky</h1>
            <div className="form-group">
              <label htmlFor="inputEmail" className="sr-only">Email</label>
              <input type="email" id="inputEmail" name="username" className="form-control" placeholder="Email"></input>
            </div>
            <div className="form-group">
              <label htmlFor="inputPassword" className="sr-only">Email</label>
              <input type="password" id="inputPassword" name="password" className="form-control" placeholder="Heslo"></input>
            </div>
            <LoginError failure={this.props.failure}/>
            <button type="submit" className="btn btn-gradient">Přihlásit se</button>
            {/* <p><a href="/password-reset">Zapomněli jste heslo?</a></p>*/}
          </form>
        </main>
      );
  }
}

export default withRouter(Login);

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import validation from '../../common/Validation';

import Loader from '../../common/Loader';

import {
  GET_EXCHANGES_LIST
} from '../../../constants/actionTypes';

const inputClassName = (touched, error, readOnly) => (
  readOnly ? 'form-control-plaintext' :
    touched ? (error ? "form-control is-invalid" : "form-control is-valid") : "form-control"
)

const renderInputField = ({ input, label, type, readOnly, meta: { touched, error, warning } }) => (
  <div className="col-12 col-md-6">
    {label && <label>{label}</label>}
    <div>
      <input {...input} type={type}
        className={inputClassName(touched, error, readOnly)} readOnly={readOnly}/>
      {touched && error && <div className="invalid-feedback">{error}</div>}
    </div>
  </div>
)

const renderChecboxField = ({input, label, readOnly, checked, id, meta: { touched, error, warning } }) => (
  <div className="col-12 col-md-6">
    {label && <label>{label}</label>}
    <div className="custom-control custom-switch">
      <input {...input} id={id} checked={checked} type="checkbox" className="custom-control-input" disabled={readOnly}></input>
      <label className="custom-control-label" htmlFor={id}></label>
    </div>
  </div>
)

const renderTextareaField = ({ input, label, type, readOnly, meta: { touched, error, warning } }) => (
  <div className="col-12">
    {label && <label>{label}</label>}
    <div class="mb-3">
      <textarea {...input} type={type}
        className={inputClassName(touched, error, readOnly)} readOnly={readOnly}></textarea>
      {touched && error && <div className="invalid-feedback">{error}</div>}
    </div>
  </div>
)

class PersonaOracleForm extends React.PureComponent {

  componentDidMount() {
    if (this.props.mode === 'edit' || this.props.mode === 'new')
      this.props.getExchangesList();
  }

  render() {

    if (this.props.mode !== 'new' && this.props.loading)
      return (
        <div><Loader loading={this.props.loading}/></div>
      );

    if ((this.props.mode === 'edit' || this.props.mode === 'new') && (this.props.loadingExchangesList))
      return (
        <div><Loader loading={this.props.loadingExchangesList}/></div>
      );

    if (this.props.mode === 'new') {
      this.props.initialValues.distributeStatus = 'true';
    }

    return(
      <form onSubmit={this.props.handleSubmit}>
        <div className="form-row">
          <Field type="text" component={renderInputField}
            name="name" label="Jméno" validate={[validation.required]}
            readOnly={this.props.readOnly}/>
          <Field type="text" component={renderInputField}
            name="title" label="Titul" validate={[validation.required]}
            readOnly={this.props.readOnly}/>
          <Field type="text" component={renderInputField}
            name="lineNumber" label="Číslo linky" validate={[validation.required, validation.isNumber]}
            readOnly={this.props.readOnly}/>

          <ExchangeSelect {...this.props}/>
          <DistributeStatus {...this.props}/>

          <Field type="text" component={renderInputField}
            name="tag" label="Tag"
            readOnly={this.props.readOnly}/>
          <Field component={renderTextareaField}
            name="description" label="Popisek"
            readOnly={this.props.readOnly}/>

          <FormButtons {...this.props}/>
        </div>
      </form>
    );
  }
  //
}

class ExchangeSelect extends React.PureComponent {
  render() {
    if (!this.props.exchangesList)
      return (<div></div>);

    return (
      <div className="col-12 col-md-6">
        <label htmlFor="exchangeId">Ústředna</label>
        {this.props.readOnly ?
          <div><input type="text" className="form-control-plaintext" value={this.props.initialValues.exchange != null ? this.props.initialValues.exchange.name : ''} readOnly/></div> :
          <Field component="select" className="form-control" name="exchangeId" disabled={this.props.readOnly} validate={[validation.required]}>
            <option value="">--- nezvoleno ---</option>
            {this.props.exchangesList.map((exchange,i) => {return (<option value={exchange.exchangeId} key={i}>{exchange.name}</option>)})}
          </Field>
        }
      </div>
    );
  }
}

class DistributeStatus extends React.PureComponent {
  render() {
    return (
      <div className="col-12 col-md-6">
        <label htmlFor="exchangeId">Distribuovat stav</label>
        {this.props.readOnly ?
          <div><input type="text" className="form-control-plaintext" value={this.props.initialValues.distributeStatus !== null ? (this.props.initialValues.distributeStatus ? 'ANO' : 'NE') : 'ANO'} readOnly/></div> :
          <Field component="select" className="form-control" name="distributeStatus" disabled={this.props.readOnly}>
            <option value="true">ANO</option>
            <option value="false">NE</option>
          </Field>
        }
      </div>
    );
  }
}

class FormButtons extends React.PureComponent {

  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
  }

  render() {
    const showSubmitButtons = this.props.mode === 'edit' || this.props.mode === 'new';

    return(
      <div className="col-12">
        { showSubmitButtons &&
          <>
            <button type="submit" className="btn btn-primary float-right" disabled={this.props.pristine || this.props.submitting}>
              Uložit
            </button>
            <button type="button" className="btn btn-secondary float-right mr-2" disabled={this.props.pristine || this.props.submitting} onClick={this.props.reset}>
              Původní hodnoty
            </button>
          </>
        }
        <Link to="/" className="btn btn-primary float-right mr-2" onClick={this.goBack}>Zpět</Link>
      </div>
    );
  }

  goBack(e) {
    e.preventDefault();
    this.props.history.goBack();
  }
}

const mapStateToProps = state => {
  return {
    exchangesList: state.persona.exchangesList,
    loadingExchangesList: state.persona.loadingExchangesList,
    loading: state.persona.loading,
    initialValues: state.persona.oracleDetail
  }
};

const mapDispatchToProps = dispatch => ({
  getExchangesList: () =>
    dispatch({ type: GET_EXCHANGES_LIST }),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'oracleDetailForm'})(withRouter(PersonaOracleForm)))

import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import { connect } from 'react-redux';

import ConfirmDialog from '../../common/ConfirmDialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

import { ROLE_NAMES } from '../../../constants/enums';

import {
  DELETE_PERSONA_ORACLE,
  DELETE_PERSONA_USER,
  SWITCH_USER_COLLAPSE_STATUS
} from '../../../constants/actionTypes';

class PersonaTableRow extends React.PureComponent {

  render() {
    const entry = this.props.entry;
    const isOracleUserCollapsed = entry.type === 'ORACLE' &&
      this.props.collapsedUsers.has(entry.userId)
      && this.props.collapsedUsers.get(entry.userId) === true;

    if (entry.type === 'USER')
      return(<PersonaUserRow {...this.props}/>);
    else {
      if (!isOracleUserCollapsed) {
        return(<PersonaOracleRow {...this.props}/>);
      }
      else {
        return(<></>);
      }
    }
  }
}

class PersonaUserRow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleUserRemove = this.handleUserRemove.bind(this);
    this.handleSwitchUserCollapseStatus = this.handleSwitchUserCollapseStatus.bind(this);
  }

  render() {
    const entry = this.props.entry;
    const name = entry.firstName + ' ' + entry.lastName;
    const isUserCollapsed = this.props.collapsedUsers.has(entry.id) && this.props.collapsedUsers.get(entry.id) === true;
    const hasOracles = entry.userHasOracles;

    return(
      <div className="row user">
        <div className="col-12 col-md-4 d-flex">
          <div className="photo show-hide d-flex" href="" onClick={this.handleSwitchUserCollapseStatus}>
            <div className={hasOracles ? 'ico-show-hide' : 'ico-show-hide invisible'}>
              {isUserCollapsed ? <FontAwesomeIcon icon={faAngleDown} size="1x" className="text-secondary"/> : <FontAwesomeIcon icon={faAngleUp} size="1x" className="text-secondary"/>}
            </div>
            <img src={'/api/adm/persona/user/' + entry.id + '/avatar'} alt={name} title={name}/>
          </div>
          <div className="name">
            <Link to={'/personas/user/' + entry.id + '/detail'} title="Detail uživatele">{name}</Link><br/>
            {ROLE_NAMES[entry.role]}
          </div>
        </div>
        <div className="col-12 col-md-3 text-left sm-p-l-25">
          {entry.lastLoginDate}<br/>{entry.lastLoginIp}
        </div>
        <div className="col-12 col-md-3 text-left sm-p-l-25">
          <a href={'mailto:' + entry.email}>{entry.email}</a>
          <br/>{entry.phone}
        </div>
        <div className="col-12 col-md-2 buttons">
          <Link to={'/personas/user/' + entry.id + '/oracle/new'} className="display-user"><img src="/img/add-user.png" alt="Přidat kartářku" title="Přidat kartářku"/></Link>
          <Link to={'/personas/user/' + entry.id + '/edit'} className="hide"><img src="/img/pencil.png" alt="Upravit" title="Upravit"/></Link>
          <Link to="/" className="hide" data-toggle="modal" data-target={'#removeUserDialog' + entry.id}><img src="/img/bin.png" alt="Smazat uživatele a jeho kartářky" title="Smazat uživatele a jeho kartářky"/></Link>
        </div>
        <ConfirmDialog id={'removeUserDialog' + entry.id} title="Odstranit uživatele" handleSubmit={this.handleUserRemove}>
          Opravdu chcete odstranit uživatele <strong>{name}</strong> a všechny jeho kartářky?
        </ConfirmDialog>
      </div>
    );
  }

  handleUserRemove(event) {
    this.props.deletePersonaUser({userId: this.props.entry.id});
    $("#removeUserDialog"+this.props.entry.id).modal("hide");
  }

  handleSwitchUserCollapseStatus(event) {
    event.stopPropagation();
    this.props.switchUserCollapseStatus({
      userId: this.props.entry.id,
    });
  }
}

class PersonaOracleRow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleOracleRemove = this.handleOracleRemove.bind(this);
  }

  render() {
    const entry = this.props.entry;
    return(
      <div className="row">
        <div className="col-12 col-md-4 d-flex">
          <div class="photo ml-4">
            <img src={'/api/adm/persona/oracle/' + entry.id + '/avatar'} alt={entry.firstName} title={entry.firstName}/>
          </div>
          <div class="name">
            <Link to={'/personas/oracle/' + entry.id + '/detail'} title="Detail kartářky">{entry.firstName} {entry.tag && <span className="badge badge-secondary">{entry.tag}</span>}</Link><br/>
            {entry.role}
          </div>
        </div>
        <div className="col-12 col-md-3 sm-p-l-25">
          Linka <span className="red d-contents">{entry.lineNumber}</span>
          <span className="badge badge-primary ml-2">{entry.exchangeName}</span>
        </div>
        <div className="col-12 col-md-3 text-left sm-p-l-25">
          {
            entry.phoneNumbers.map((phone, i) => { return (
              <span key={i}>
                {phone.phoneNumber}&nbsp;&nbsp;<span className="red d-contents">{phone.tariffPrice} Kč</span><br/>
            </span>
            )})
          }
        </div>
        <div className="col-12 col-md-2 buttons">
          <Link to="/" className="display-user"><img src="/img/add-user.png" alt="Přidat kartářku" title="Přidat kartářku"/></Link>
          <Link to={'/personas/oracle/' + entry.id + '/edit'} className="hide"><img src="/img/pencil.png" alt="Upravit" title="Upravit"/></Link>
          <Link to="/" className="hide-user" data-toggle="modal" data-target={'#removeOracleFromUserDialog' + entry.id}><img src="/img/bin.png" alt="Smazat kartářku" title="Smazat kartářku"/></Link>
        </div>
        <ConfirmDialog id={'removeOracleFromUserDialog' + entry.id} title="Odstranit kartářku" handleSubmit={this.handleOracleRemove}>
          Opravdu chcete odstranit kartářku <strong>{entry.firstName}</strong>?
        </ConfirmDialog>
      </div>
    );
  }

  handleOracleRemove(event) {
    this.props.deletePersonaOracle({oracleId: this.props.entry.id});
    $("#removeOracleFromUserDialog"+this.props.entry.id).modal("hide");
  }
}

const mapStateToProps = state => {
  return {
    collapsedUsers: state.persona.collapsedUsers
  }
};

const mapDispatchToProps = dispatch => ({
  deletePersonaOracle: payload =>
    dispatch({ type: DELETE_PERSONA_ORACLE, payload }),
  deletePersonaUser: payload =>
    dispatch({ type: DELETE_PERSONA_USER, payload }),
  switchUserCollapseStatus: payload =>
    dispatch({ type: SWITCH_USER_COLLAPSE_STATUS, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonaTableRow)

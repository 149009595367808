import React from 'react';
import {withRouter} from 'react-router-dom';

class LoginError extends React.Component {
  render() {
    const isError = this.props.location.search != null && this.props.location.search.includes("login_error=1");

    if (isError)
      return (
        <div className="alert alert-danger" role="alert">Chyba přihlášení.</div>
      );
    else
      return (null);
  }
}

export default withRouter(LoginError);

import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../common/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import {
  GET_EXCHANGES_LIST
} from '../../../constants/actionTypes';

class OracleFilter extends React.PureComponent {

  componentDidMount() {
    this.props.getExchangesList();
  }

  render() {
    if (this.props.loadingExchangesList)
      return (
        <div><Loader loading={this.props.loadingExchangesList}/></div>
      );

    return(
      <form>
        <div className="form-row">
          <div className="form-group col-md-6 col-lg-3">
            <label htmlFor="userName">Jméno uživatele</label>
            <input id="userName" type="text" className="form-control" placeholder="Jméno uživatele"/>
          </div>
          <div className="form-group col-md-6 col-lg-3">
            <label htmlFor="oracleName">Jméno kartářky</label>
            <input id="oracleName" type="text" className="form-control" placeholder="Jméno kartářky"/>
          </div>
          <div className="form-group col-md-6 col-lg-3">
            <label htmlFor="exchangeId">Ústředna</label>
            <select id="exchangeId" className="form-control" placeholder="Ústředna">
              <option value="">--- nezvoleno ---</option>
              {this.props.exchangesList.map((exchange,i) => {return (<option value={exchange.exchangeId} key={i}>{exchange.name}</option>)})}
            </select>
          </div>
          <div className="form-group col-md-6 col-lg-3">
            <label htmlFor="lineNumber">Linka</label>
            <div className="input-group">
              <input id="lineNumber" type="text" className="form-control" placeholder="Linka"/>
              <div className="input-group-append">
                <button type="submit" className="btn btn-outline-primary">
                <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    exchangesList: state.page.exchangesList,
    loadingExchangesList: state.page.loadingExchangesList,
  }
};

const mapDispatchToProps = dispatch => ({
  getExchangesList: payload =>
    dispatch({ type: GET_EXCHANGES_LIST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OracleFilter);

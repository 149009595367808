import request from 'superagent';

export default {
  getAuthenticatedUser: () => {
    const req = request
      .get('/api/user')
      .withCredentials();
    return req;
  },

  logoutUser: () => {
    const req = request
      .post('/logout-endpoint')
      .withCredentials();
    return req;
  }
}

import request from 'superagent';

export default {
  getAudit: () => {
    const req = request
      .get('/audit')
      .withCredentials();
    return req;
  }
}

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import ConfirmDialog from '../../common/ConfirmDialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'

import {
  SET_PAGE_ORACLE_ACTIVE,
  SET_PAGE_ORACLE_OVERRIDE,
  REMOVE_ORACLE_FROM_PAGE
} from '../../../constants/actionTypes';

class PersonaOraclePagesTable extends React.PureComponent {
  render() {
    return (
      <div className="tabulka">
        <div className="row header">
          <div className="col-4"><Link to={'/personas/oracle/' + this.props.oracleDetail.oracleId + '/addpage'} className="text-success" title="Přidat na web"><FontAwesomeIcon icon={faPlusSquare} size="lg"/></Link> Stránka</div>
          <div className="col-2 text-left">Přepsat</div>
          <div className="col-2"></div>
          <div className="col-2">Aktivní</div>
          <div className="col-1"></div>
        </div>
        {
          this.props.pages.length > 0 ?
          this.props.pages.map((page, i) => { return (
            <PersonaOraclePagesTableRow entry={page} key={i} {...this.props} />) }
          )
          : <div className="text-left p-3"><span className="badge badge-warning">Žádná data</span> Kartářka není přiřazena na žádném webu.</div>
        }
      </div>
    );
  }
}

class PersonaOraclePagesTableRow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handlePageActiveChange = this.handlePageActiveChange.bind(this);
    this.handlePageStatusOverrideValueChange = this.handlePageStatusOverrideValueChange.bind(this);
    this.handlePageStatusOverrideChange = this.handlePageStatusOverrideChange.bind(this);
    this.handleOracleRemove = this.handleOracleRemove.bind(this);
  }

  render() {
    const entry = this.props.entry;

    if (this.props.oracleDetail == null)
      return (<></>);

    const onlineRaw = this.props.oracleDetail.online !== undefined ? this.props.oracleDetail.online : false;
    const overrideButtonLabel = entry.opStatusOverrideActive ? 'Uvolnit' : 'Přepsat';
    const deactivateButtonLabel = onlineRaw ? 'Deaktivovat' : 'Aktivovat';

    console.log('onlineRaw = ' + onlineRaw);


    return (
      <div className="row">
        <div className="col-12 col-md-4 d-flex">
          <div className ="photo">
            <Link to={'/sites/' + entry.page.pageId + '/detail'}>
              <img src={'/api/adm/page/' + entry.page.pageId + '/avatar'} alt={entry.page.name} title={entry.page.name}/>
            </Link>
          </div>
          <div className="name">
            <Link to={'/sites/' + entry.page.pageId + '/detail'}>{entry.page.name}</Link>
          </div>
          <a href="/" className="d-block d-md-none ml-auto" data-toggle="modal" data-target={'#removeOracleFromPageDialog' + entry.page.pageId}><img src="img/cross.png" alt="Odebrat kartářku z webu" title="Odebrat kartářku z webu"/></a>
        </div>
        <div className="col-6 col-md-2">
          {onlineRaw && <>
          <div className="form-group hide">
            <select id={'status-' + entry.page.pageId} className="form-control form-control-sm" value={entry.opStatusOverrideValue ? entry.opStatusOverrideValue : ''} disabled={this.props.changingPageOracleOverride} onChange={this.handlePageStatusOverrideValueChange}>
              <option value="">--- nezvoleno ---</option>
              <option value="OFF">Offline</option>
              <option value="ONC">Obsazeno</option>
            </select>
            <label htmlFor={'status-' + entry.page.pageId} className="mt-1">Přepsat stavem</label>
          </div>
          </>}
        </div>
        <div className="col-3 col-md-2">
          {onlineRaw && <>
          <div className="custom-control custom-switch hide">
            <input type="checkbox" className="custom-control-input" id={'statusOver-' + entry.page.pageId} disabled={this.props.changingPageOracleOverride} checked={entry.opStatusOverrideActive} onChange={this.handlePageStatusOverrideChange} />
            <label className="custom-control-label" htmlFor={'statusOver-' + entry.page.pageId}></label>
          </div>
          <span className="d-block hide">{overrideButtonLabel}</span>
          </>}
        </div>
        <div className="col-3 col-md-2">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id={'deactive-' + entry.page.pageId} disabled={this.props.changingPageOracleActive} checked={onlineRaw} onChange={this.handlePageActiveChange}/>
            <label className="custom-control-label" htmlFor={'deactive-' + entry.page.pageId}></label>
          </div>
          <span className="d-block">{deactivateButtonLabel}</span>
        </div>
        <div className="col-md-2 buttons d-none d-md-flex">
          <a href="/" className="hide-user" data-toggle="modal" data-target={'#removeOracleFromPageDialog' + entry.page.pageId}><img src="img/cross.png" alt="Odebrat kartářku z webu" title="Odebrat kartářku z webu"/></a>
        </div>
        <div className="w-100 my-2 d-md-none"></div>
        <ConfirmDialog id={'removeOracleFromPageDialog' + entry.page.pageId} title="Odebrat kartářku ze stránky" handleSubmit={this.handleOracleRemove}>
          Opravdu chcete odebrat kartářku ze stránky <strong>{entry.page.name}</strong>?
        </ConfirmDialog>
      </div>
    );
  }

  handlePageStatusOverrideValueChange(event) {
    event.stopPropagation();

    this.props.setPageOracleOverride(
      {
        pageId: this.props.entry.page.pageId,
        oracleId: this.props.entry.id.oracleId,
        active: event.target.value !== '',
        valueOverride: event.target.value !== '' ? event.target.value : 'ONC'
      }
    );
  }

  handlePageStatusOverrideChange(event) {
    event.stopPropagation();

    this.props.setPageOracleOverride(
      {
        pageId: this.props.entry.page.pageId,
        oracleId: this.props.entry.id.oracleId,
        active: !this.props.entry.opStatusOverrideActive,
        valueOverride: 'ONC'
      }
    );
  }

  handlePageActiveChange(event) {
    event.stopPropagation();
    this.props.setPageOracleActive({
      pageId: this.props.entry.page.pageId,
      oracleId: this.props.entry.id.oracleId,
      active: !this.props.oracleDetail.online
    });
  }

  handleOracleRemove(event) {
    this.props.removeOracleFromPage({
      pageId: this.props.entry.page.pageId,
      oracleId: this.props.entry.id.oracleId
    });
    $("#removeOracleFromPageDialog"+this.props.entry.page.pageId).modal("hide");
  }
}

const mapDispatchToProps = dispatch => ({
  setPageOracleActive: payload =>
    dispatch({ type: SET_PAGE_ORACLE_ACTIVE, payload }),
  setPageOracleOverride: payload =>
    dispatch({ type: SET_PAGE_ORACLE_OVERRIDE, payload}),
  removeOracleFromPage: payload =>
    dispatch({ type: REMOVE_ORACLE_FROM_PAGE, payload })
});

const mapStateToProps = state => {
  return {
    oracleDetail: state.persona.oracleDetail,
    changingPageOracleActive: state.admdash.changingPageOracleActive,
    changingPageOracleOverride: state.admdash.changingPageOracleOverride
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonaOraclePagesTable);

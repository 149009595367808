import React from 'react';
import { Link } from 'react-router-dom';


class BreadcrumbMenu extends React.PureComponent {

  render() {
    return (
      <nav aria-label="breadcrumb" className="float-left">
        <ol className="breadcrumb">
          {
            this.props.crumbs.map((crumb, i) => {
              return <BreadcrumbMenuItem key={i} active={crumb.active} label={crumb.label} to={crumb.to}>{crumb.title}</BreadcrumbMenuItem>
            })
          }
        </ol>
      </nav>
    );
  }
}

class BreadcrumbMenuItem extends React.PureComponent {
  render() {
    if (this.props.active) {
      return (
        <li className="breadcrumb-item active" aria-current="page">
          {this.props.children}
          {this.props.label ? <div className="ttip">{this.props.label}</div> : null}
        </li>
      );
    }
    else {
      return (
        <li className="breadcrumb-item">
          <Link to={this.props.to}>{this.props.children}</Link>
          {this.props.label ? <div className="ttip">{this.props.label}</div> : null}
        </li>
      );
    }
  }
}

export default BreadcrumbMenu;

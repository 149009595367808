import React from 'react';
import { connect } from 'react-redux';

import OracleOnlineStatus from './OracleOnlineStatus';
import OracleOncallStatus from './OracleOncallStatus';
import OraclePhoneNumber from './OraclePhoneNumber';
import OracleCard from './OracleCard';
import Loader from '../../common/Loader'

import Messages from '../../common/Messages';

import {
  PAGE_CHANGE,
  GET_CURRENT_USER_DETAIL,
  LOAD_CURRENT_USER_ORACLES
} from '../../../constants/actionTypes';

import {
  PAGE_USER_DASHBOARD
} from '../../../constants/pageTypes';

class MyOraclesListView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.props.onPageChange(PAGE_USER_DASHBOARD);
  }

  componentDidMount() {
    this.props.loadUserDetail();
    this.props.loadUserOracles();
  }

  render() {
    return (
      <main id="user-dashboard" className="col-sm-12 col-lg-9">

        <Messages/>

        <div className="row user-status">
          <OracleOnlineStatus size={this.props.currentUserDetail !== null && this.props.currentUserDetail.role === 'ADM' ? 6 : 12}/>
          {this.props.currentUserDetail !== null && this.props.currentUserDetail.role === 'ADM' && <OracleOncallStatus size={6}/>}
        </div>

        {
          this.props.loadingOraclesList ?
            <Loader loading={this.props.loadingOraclesList}/> :
            this.props.oraclesList.map((oracle, i) => { return (
              <OracleCard key={i} oracle={oracle}/>
            )})
        }
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onPageChange: payload =>
    dispatch({ type: PAGE_CHANGE, payload }),
  loadUserDetail: () =>
    dispatch({ type: GET_CURRENT_USER_DETAIL }),
  loadUserOracles: () =>
    dispatch({ type: LOAD_CURRENT_USER_ORACLES })
});

const mapStateToProps = state => {
  return {
    loadingOraclesList: state.usrdash.loadingOraclesList,
    currentUserDetail: state.usrdash.currentUserDetail,
    oraclesList: state.usrdash.oraclesList
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOraclesListView);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class PageListHeader extends React.PureComponent {

  render() {
    return (
      <div className="float-right">
        Evidujeme <span className="red">{this.props.personasUsersCount}</span> uživatelů a <span className="red">{this.props.personasOraclesCount}</span> kartářek.
        <span className="d-none d-md-inline ml-2"></span>
        <Link to="/personas/user/0/new" className="btn btn-green btn-sm">Nový uživatel</Link>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    personasUsersCount: state.persona.personasUsersCount,
    personasOraclesCount: state.persona.personasOraclesCount
  }
};

export default connect(mapStateToProps)(PageListHeader);

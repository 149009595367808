import {
  GET_AUDIT,
  GET_AUDIT_OK,
  GET_AUDIT_ERR
} from '../constants/actionTypes';

export default (state = {
  loading: true,
  auditList: []
}, action) => {
  switch (action.type) {
    case GET_AUDIT:
      return {
        ...state,
        loading: true,
        auditList: []
      }
    case GET_AUDIT_OK:
      return {
        ...state,
        loading: false,
        auditList: action.payload
      }
    case GET_AUDIT_ERR:
      return {
        ...state,
        loading: false
      }

    default:
      return state;
  }
};

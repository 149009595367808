import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import Loader from '../../common/Loader';
import Messages from '../../common/Messages';
import BreadcrumbMenu from '../../menu/BreadcrumbMenu';
import ImageUploadDialog from '../../common/ImageUploadDialog';
import $ from 'jquery';

import PersonaUserForm from './PersonaUserForm';
import PersonaUserOraclesTable from './PersonaUserOraclesTable';
import ChangePasswordDialog from '../../common/ChangePasswordDialog';
import DetailPhoto from '../../common/DetailPhoto';


import { ROLE_NAMES } from '../../../constants/enums';

import {
  GET_PERSONA_USER,
  GET_PERSONA_USER_ORACLES_LIST,
  UPDATE_PERSONA_USER,
  CREATE_PERSONA_USER,
  PERSONA_USER_PASSWORD_CHANGE,
  UPLOAD_USER_AVATAR
} from '../../../constants/actionTypes';

class PersonaUserDetailView extends React.PureComponent {

  componentDidMount() {
    if (this.props.match.params.mode !== 'new') {
      this.props.getPersonaUser({id: this.props.match.params.id});
      this.props.getPersonaUserOraclesList({id: this.props.match.params.id});
    }
  }

  render() {
    const mode = this.props.match.params.mode;

    if (this.props.loading)
      return (
        <main id="user" className="col-sm-12 col-lg-9">
          <div><Loader loading={this.props.loading}/></div>
        </main>
      );

    if (this.props.redirectToList)
      return (<Redirect to='/personas' />);

    const breadcrumbs = [
      {
        title: "Správa kartářek",
        to: '/personas'
      },
      {
        title: mode !== 'new' ? this.props.userDetail.firstName + ' ' + this.props.userDetail.lastName : 'Nový uživatel',
        active: true,
        label: mode !== 'new' ? ROLE_NAMES[this.props.userDetail.role] : null
      }
    ];

    return (
      <React.Fragment>
        <main id="user" className="col-sm-12 col-lg-9">
          <Messages/>
          <div>
            <div className="clearfix">
              <BreadcrumbMenu crumbs={breadcrumbs}/>
              {mode !== 'new' &&
                <div className="float-right">
                  <div className="dropdown">
                    <button className="btn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src="/img/cog.png" alt="Možnosti" title="Možnosti"/>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                      {
                        mode === 'detail' ? <Link className="dropdown-item" to={'/personas/user/' + this.props.userDetail.userId + '/edit'}>Upravit údaje</Link> :
                          <Link className="dropdown-item" to={'/personas/user/' + this.props.userDetail.userId + '/detail'}>Zpět na detail</Link>
                      }
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item" to={'/personas/user/' + this.props.userDetail.userId + '/oracle/new'}>Vytvořit kartářku</Link>
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item" to="/" data-toggle="modal" data-target="#imageUploadDialog">Změnit fotku</Link>
                      <Link className="dropdown-item" to="/" data-toggle="modal" data-target="#changePasswordModal">Změnit heslo</Link>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <DetailPhoto src={'/api/adm/persona/user/' + this.props.userDetail.userId + '/avatar'}
                   xt={this.props.avatarPhotoUpdateDate}
                   alt={mode !== 'new' ? this.props.userDetail.firstName + ' ' + this.props.userDetail.lastName : 'Nový uživatel'}
                   loading={this.props.uploadingAvatarPhoto}
                   defaultSrc="/img/user-w.png" mode={mode}/>
              </div>
              <div className="col-12 col-md-8">
                <PersonaUserForm mode={mode} readOnly={mode === 'detail'} onSubmit={mode === 'edit' ? this.handleUserUpdate : this.handleUserCreate}/>
              </div>
            </div>
            { mode === 'detail' && <PersonaUserOraclesTable/> }
          </div>
        </main>
        <ChangePasswordDialog id="changePasswordModal" requireOriginal={false} onSubmit={this.handleChangePassword}/>
        <ImageUploadDialog id="imageUploadDialog" title="Změna fotky uživatele"
          handleImageSubmit={this.handleImageSubmit}
          previewWidth={'250px'} previewHeight={'300px'}
          maxFileSize={10 * 1024 * 1024}/>
      </React.Fragment>
    );
  }

  handleImageSubmit = image => {
    this.props.uploadUserAvatar({
      userId: this.props.userDetail.userId,
      file: image
    });
    $("#imageUploadDialog").modal("hide");
  }

  handleUserUpdate = values => {
    this.props.updatePersonaUser(values);
  }

  handleUserCreate = values => {
    this.props.createPersonaUser(values);
  }

  handleChangePassword = values => {
    values['userId'] = this.props.userDetail.userId;
    this.props.changePassword(values);
    $("#changePasswordModal").modal("hide");
  }
}

const mapStateToProps = state => {
  return {
    loading: state.persona.loading,
    userDetail: state.persona.userDetail,
    error: state.persona.error,
    errorDetail: state.persona.errorDetail,
    redirectToList: state.persona.redirectToList,
    avatarPhotoUpdateDate: state.persona.avatarPhotoUpdateDate,
    uploadingAvatarPhoto: state.persona.uploadingAvatarPhoto
  }
};

const mapDispatchToProps = dispatch => ({
  getPersonaUser: payload =>
    dispatch({ type: GET_PERSONA_USER, payload }),
  getPersonaUserOraclesList: payload =>
    dispatch({ type: GET_PERSONA_USER_ORACLES_LIST, payload }),
  updatePersonaUser: payload =>
    dispatch({ type: UPDATE_PERSONA_USER, payload }),
  createPersonaUser: payload =>
    dispatch({ type: CREATE_PERSONA_USER, payload }),
  changePassword: payload =>
    dispatch({ type: PERSONA_USER_PASSWORD_CHANGE, payload }),
  uploadUserAvatar: payload =>
    dispatch({ type: UPLOAD_USER_AVATAR, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PersonaUserDetailView));

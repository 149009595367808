import { takeLatest, all } from 'redux-saga/effects';
import { processRequest } from './util';

import adminDashboardApi from '../api/admdash';

import {
  GET_PAGES_SUMMARY,
  GET_PAGES_SUMMARY_OK,
  GET_PAGES_SUMMARY_ERR,
  SET_PAGE_ORACLE_ACTIVE,
  SET_PAGE_ORACLE_ACTIVE_OK,
  SET_PAGE_ORACLE_ACTIVE_ERR,
  SET_PAGE_ORACLE_OVERRIDE,
  SET_PAGE_ORACLE_OVERRIDE_OK,
  SET_PAGE_ORACLE_OVERRIDE_ERR
} from '../constants/actionTypes';

function* getPagesSummary(action) {
  yield processRequest(
    action,
    adminDashboardApi.getPagesSummary,
    GET_PAGES_SUMMARY_OK,
    GET_PAGES_SUMMARY_ERR
  );
}

function* setPageOracleActive(action) {
  yield processRequest(
    action,
    adminDashboardApi.setPageOracleActive,
    SET_PAGE_ORACLE_ACTIVE_OK,
    SET_PAGE_ORACLE_ACTIVE_ERR
  );
}

function* setPageOracleOverride(action) {
  yield processRequest(
    action,
    adminDashboardApi.setPageOracleOverride,
    SET_PAGE_ORACLE_OVERRIDE_OK,
    SET_PAGE_ORACLE_OVERRIDE_ERR
  );
}


function* adminDashboardApiService() {
  yield all([
    takeLatest(GET_PAGES_SUMMARY, getPagesSummary),
    takeLatest(SET_PAGE_ORACLE_ACTIVE, setPageOracleActive),
    takeLatest(SET_PAGE_ORACLE_OVERRIDE, setPageOracleOverride),
  ]);
}

export default adminDashboardApiService

import React from 'react';

class Loader extends React.PureComponent {
  render() {
    return (
      this.props.loading ?
        <div className="spinner-grow m-5" role="status"><span className="sr-only">Nahrávám data...</span></div> :
        null
    )
  }
}

export default Loader;

import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import $ from 'jquery';

import Loader from '../../common/Loader';
import Messages from '../../common/Messages';
import BreadcrumbMenu from '../../menu/BreadcrumbMenu';
import PageForm from './PageForm';
import ChangePageApiPasswordDialog from './ChangePageApiPasswordDialog';
import PageOraclesTable from './PageOraclesTable';
import DetailPhoto from '../../common/DetailPhoto';
import ImageUploadDialog from '../../common/ImageUploadDialog';

import {
  GET_PAGE,
  UPDATE_PAGE,
  CREATE_PAGE,
  CHANGE_PAGE_API_PASSWORD,
  UPLOAD_PAGE_AVATAR
} from '../../../constants/actionTypes';

class PageDetailView extends React.PureComponent {

  componentDidMount() {
    if (this.props.match.params.mode !== 'new') {
      this.props.getPage({id: this.props.match.params.id});
    }
  }

  render() {
    const mode = this.props.match.params.mode;

    if (this.props.loading || !this.props.pageDetail.pageId)
      return (
        <main id="web" className="col-sm-12 col-lg-9">
          <div><Loader loading={this.props.loading}/></div>
        </main>
      );

    if (this.props.redirectToList)
      return (<Redirect to='/sites' />);

    const breadcrumbs = [
      {
        title: "Správa webů",
        to: '/sites'
      },
      {
        title: mode !== 'new' ? this.props.pageDetail.name : 'Nový web',
        active: true
      }
    ];

    return(
      <React.Fragment>
        <main id="web" className="col-sm-12 col-lg-9">
          <Messages/>
          <div>
            <div className="clearfix">
              <BreadcrumbMenu crumbs={breadcrumbs}/>
              {mode === 'detail' &&
                <div className="float-right">
                  <div className="dropdown">
                    <button className="btn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src="/img/cog.png" alt="Možnosti" title="Možnosti"/>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                      <Link className="dropdown-item" to={'/sites/' + this.props.pageDetail.pageId + '/addoracle'}>Přidat kartářku</Link>
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item" to={'/sites/' + this.props.pageDetail.pageId + '/edit'}>Změnit údaje</Link>
                      <Link className="dropdown-item" to="/" data-toggle="modal" data-target="#imageUploadDialog">Změnit obrázek</Link>
                      <Link className="dropdown-item" to="/" data-toggle="modal" data-target="#changePageApiPasswordModal">Změnit heslo pro API</Link>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="photo-big mb-2">
                  <DetailPhoto src={'/api/adm/page/' + this.props.pageDetail.pageId + '/avatar'}
                     xt={this.props.avatarPhotoUpdateDate}
                     alt={mode !== 'new' ? this.props.pageDetail.name : 'Nový web'}
                     loading={this.props.uploadingAvatarPhoto}
                     defaultSrc="/img/web.png" mode={mode}/>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <PageForm mode={mode} readOnly={mode === 'detail'} onSubmit={mode === 'edit' ? this.handlePageUpdate : this.handlePageCreate}/>
              </div>
            </div>
            {
              mode === 'detail' && <PageOraclesTable/>
            }
            <ChangePageApiPasswordDialog id="changePageApiPasswordModal" onSubmit={this.handleChangePageApiPassword}/>
          </div>
        </main>
        <ImageUploadDialog id="imageUploadDialog" title="Změna fotky stránky"
          handleImageSubmit={this.handleImageSubmit}
          previewWidth={'250px'} previewHeight={'300px'}
          maxFileSize={10 * 1024 * 1024}/>
      </React.Fragment>
    );
  }

  handleImageSubmit = image => {
    this.props.uploadPageAvatar({
      pageId: this.props.pageDetail.pageId,
      file: image
    });
    $("#imageUploadDialog").modal("hide");
  }

  handlePageCreate = values => {
    this.props.createPage(values);
  }

  handlePageUpdate = values => {
    this.props.updatePage(values);
  }

  handleChangePageApiPassword = values => {
    values['id'] = this.props.pageDetail.pageId;
    this.props.changePageApiPassword(values);
    $("#changePageApiPasswordModal").modal("hide");
  }
}

const mapStateToProps = state => {
  return {
    loading: state.page.loading,
    redirectToList: state.page.redirectToList,
    pageDetail: state.page.pageDetail,
    avatarPhotoUpdateDate: state.page.avatarPhotoUpdateDate,
    uploadingAvatarPhoto: state.page.uploadingAvatarPhoto
  }
};

const mapDispatchToProps = dispatch => ({
  getPage: payload =>
    dispatch({ type: GET_PAGE, payload }),
  changePageApiPassword: payload =>
    dispatch({ type: CHANGE_PAGE_API_PASSWORD, payload}),
  updatePage: payload =>
    dispatch({ type: UPDATE_PAGE, payload }),
  createPage: payload =>
    dispatch({ type: CREATE_PAGE, payload }),
  uploadPageAvatar: payload =>
    dispatch({ type: UPLOAD_PAGE_AVATAR, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageDetailView));

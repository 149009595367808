import { takeLatest, put, call, all } from 'redux-saga/effects';
import { processRequest } from './util';

import commonApi from '../api/common';

import {
  AUTHENTICATED_USER_LOAD,
  AUTHENTICATED_USER_LOAD_OK,
  AUTHENTICATED_USER_LOAD_ERR,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  LOGOUT,
  LOGOUT_OK,
  LOGOUT_ERR
} from '../constants/actionTypes';

function* getAuthenticatedUser(action) {
  try {
    const res = yield call(commonApi.getAuthenticatedUser, action.payload);
    const data = JSON.parse(res.text);
    yield put({type: AUTHENTICATED_USER_LOAD_OK, payload: data});
    if (data && data.username)
      yield put({type: AUTH_SUCCESS, payload: data});
    else
      yield put({type: AUTH_FAILURE, payload: data});
  } catch (e) {
    yield put({type: AUTHENTICATED_USER_LOAD_ERR, payload: e});
    if (e.response && e.response.statusCode === 401)
      yield put({type: AUTH_FAILURE, payload: e});
  }
}

function* lougoutUser(action) {
  yield processRequest(
    action,
    commonApi.logoutUser,
    LOGOUT_OK,
    LOGOUT_ERR
  );
}

function* commonApiService() {
  yield all([
    takeLatest(AUTHENTICATED_USER_LOAD, getAuthenticatedUser),
    takeLatest(LOGOUT, lougoutUser)
  ]);
}

export default commonApiService

import {
  GET_CURRENT_USER_DETAIL,
  GET_CURRENT_USER_DETAIL_OK,
  GET_CURRENT_USER_DETAIL_ERR,
  CHANGE_CURRENT_USER_ONLINE_STATUS,
  CHANGE_CURRENT_USER_ONLINE_STATUS_OK,
  CHANGE_CURRENT_USER_ONLINE_STATUS_ERR,
  CHANGE_CURRENT_USER_ONCALL_STATUS,
  CHANGE_CURRENT_USER_ONCALL_STATUS_OK,
  CHANGE_CURRENT_USER_ONCALL_STATUS_ERR,
  LOAD_CURRENT_USER_ORACLES,
  LOAD_CURRENT_USER_ORACLES_OK,
  LOAD_CURRENT_USER_ORACLES_ERR
} from '../constants/actionTypes';

export default (state = {
  loadingCurrentUserDetail: true,
  loadingOraclesList: true,
  currentUserDetail: null,
  oraclesList: [],
  error: false,
  errorDetail: null,
  onlineStatus: null,
  oncallStatus: null,
  changingOnlineStatus: false,
  changingOncallStatus: false
}, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_DETAIL:
      return {
        ...state,
        loadingCurrentUserDetail: true,
        error: false,
        errorDetail: null
      };
    case GET_CURRENT_USER_DETAIL_OK:
      return {
        ...state,
        loadingCurrentUserDetail: false,
        currentUserDetail: action.payload,
        onlineStatus: action.payload.operatorAppStatusOnline,
        oncallStatus: action.payload.operatorAppStatusOncall,
        error: false,
        errorDetail: null
      };
    case GET_CURRENT_USER_DETAIL_ERR:
      return {
        ...state,
        loadingCurrentUserDetail: false,
        currentUserDetail: null,
        onlineStatus: null,
        oncallStatus: null,
        error: true,
        errorDetail: action.payload
      };
    case LOAD_CURRENT_USER_ORACLES:
      return {
        ...state,
        loadingOraclesList: true,
        error: false,
        errorDetail: null
      };
    case LOAD_CURRENT_USER_ORACLES_OK:
      return {
        ...state,
        loadingOraclesList: false,
        oraclesList: action.payload,
        error: false,
        errorDetail: null
      };
    case LOAD_CURRENT_USER_ORACLES_ERR:
      return {
        ...state,
        loadingOraclesList: false,
        oraclesList: [],
        error: true,
        errorDetail: action.payload
      };
    case CHANGE_CURRENT_USER_ONLINE_STATUS:
      return {
        ...state,
        changingOnlineStatus: true
      };
    case CHANGE_CURRENT_USER_ONLINE_STATUS_OK:
      return {
        ...state,
        changingOnlineStatus: false,
        onlineStatus: action.payload.value
      };
    case CHANGE_CURRENT_USER_ONLINE_STATUS_ERR:
      return {
        ...state,
        changingOnlineStatus: false,
        onlineStatus: null,
        error: true,
        errorDetail: action.payload
      };
    case CHANGE_CURRENT_USER_ONCALL_STATUS:
      return {
        ...state,
        changingOncallStatus: true
      };
    case CHANGE_CURRENT_USER_ONCALL_STATUS_OK:
      return {
        ...state,
        changingOncallStatus: false,
        oncallStatus: action.payload.value
      };
    case CHANGE_CURRENT_USER_ONCALL_STATUS_ERR:
      return {
        ...state,
        changingOncallStatus: false,
        oncallStatus: null,
        error: true,
        errorDetail: action.payload
      };
    default:
      return state;
  }
};

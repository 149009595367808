import request from 'superagent';

export default {
  getCurrentUserDetail: () => {
    const req = request
      .get('/api/usr/detail')
      .withCredentials();
    return req;
  },

  changeCurrentUserPassword: (payload) => {
    const req = request
      .put('/api/usr/password')
      .send(payload)
      .withCredentials();
    return req;
  },

  changeCurrentUserOnlineStatus: (payload) => {
    const req = request
      .put('/api/usr/online')
      .send(payload)
      .withCredentials();
    return req;
  },

  changeCurrentUserOncallStatus: (payload) => {
    const req = request
      .put('/api/usr/oncall')
      .send(payload)
      .withCredentials();
    return req;
  },

  loadCurrentUserOracles: (payload) => {
    const req = request
      .get('/api/usr/oracles')
      .withCredentials();
    return req;
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loader from '../../common/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import {
  FILTER_NUMBERS_FOR_SELECTION,
  ASSIGN_NUMBER_TO_ORACLE
} from '../../../constants/actionTypes';

class NumbersTable extends React.PureComponent {

  componentDidMount() {
    this.props.getNumbersForSelect({
      id: this.props.match.params.id
    });
  }

  render() {
    if (this.props.loadingNumbersForSelect)
      return (
          <div><Loader loading={this.props.loadingNumbersForSelect}/></div>
      );

    return(
      <table className="table">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Telefonní číslo</th>
            <th scope="col">Kreditové</th>
            <th scope="col">Cena</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.numbersForSelectList.length > 0 ?
            this.props.numbersForSelectList.map((number, i) => { return (
              <NumbersTableRow key={i} number={number} {...this.props}/>
            )})
            : <tr><td className="text-left p-3" colSpan="4"><span className="badge badge-warning">Žádná data</span> Nejsou k dispozici žádná telefonní čísla, která by bylo možné přiřadit kartářce.</td></tr>
          }
        </tbody>
      </table>
    );
  }
}

class NumbersTableRow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.assignNumber = this.assignNumber.bind(this);
  }

  render() {
    return(
      <tr>
        <th scope="row">
          <button className="btn btn-outline-success btn-sm" disabled={this.props.assigningNumberToOracle} onClick={this.assignNumber}><FontAwesomeIcon icon={faPlus} /></button>
        </th>
        <td>{this.props.number.stateCode} <span className="font-weight-bold">{this.props.number.phoneNumber}</span></td>
        <td>{this.props.number.creditNumber ? 'ANO' : 'NE'}</td>
        <td>{this.props.number.tariffPrice} Kč/min</td>
      </tr>
    );
  }

  assignNumber() {
    this.props.assignNumber({
      numberId: this.props.number.phoneNumberId,
      oracleId: this.props.match.params.id
    });
  }
}

const mapStateToProps = state => {
  return {
    loadingNumbersForSelect: state.persona.loadingNumbersForSelect,
    numbersForSelectList: state.persona.numbersForSelectList,
    assigningNumberToOracle: state.page.assigningNumberToOracle
  }
};

const mapDispatchToProps = dispatch => ({
  getNumbersForSelect: payload =>
    dispatch({ type: FILTER_NUMBERS_FOR_SELECTION, payload }),
  assignNumber: payload =>
    dispatch({ type: ASSIGN_NUMBER_TO_ORACLE, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NumbersTable));

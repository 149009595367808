import request from 'superagent';

export default {
  getPagesSummary: () => {
    const req = request
      .get('/api/adm/pagesum')
      .withCredentials();
    return req;
  },

  setPageOracleActive: (payload) => {
    const req = request
      .put('/api/adm/page/oracle/active')
      .send(payload)
      .withCredentials();
    return req;
  },

  setPageOracleOverride: (payload) => {
    const req = request
      .put('/api/adm/page/oracle/override')
      .send(payload)
      .withCredentials();
    return req;
  },
}

import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../common/Loader';
import PageTableRow from './PageTableRow';

class PageTable extends React.PureComponent {
  render() {

    if (this.props.loading)
      return (
        <Loader loading={this.props.loading}/>
      );

    return (
      <div className="tabulka">
        <div className="row header">
          <div className="col-7">Web</div>
          <div className="col-2">Aktivní</div>
        </div>
        {
          this.props.pagesList.map((page, i) => { return (
            <PageTableRow entry={page} key={i}/>) }
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.page.loading,
    pagesList: state.page.pagesList
  }
};

export default connect(mapStateToProps)(PageTable);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class PersonaListHeader extends React.PureComponent {

  render() {
    return (
      <div className="float-right">
          Evidujeme <span className="red">{this.props.pagesCount}</span> webů. <Link to="/sites/0/new" className="btn btn-green btn-sm ml-2">Nový web</Link>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pagesCount: state.page.pagesCount
  }
};

export default connect(mapStateToProps)(PersonaListHeader);

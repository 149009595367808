import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import ConfirmDialog from '../../common/ConfirmDialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'

import {
  REMOVE_NUMBER_FROM_ORACLE
} from '../../../constants/actionTypes';

class PersonaOraclePhoneTable extends React.PureComponent {
  render() {
    return (
      <div className="tabulka">
        <div className="row header">
          <div className="col-6"><Link to={'/personas/oracle/' + this.props.oracleDetail.oracleId + '/addnum'} className="text-success" title="Přidat telefonní číslo"><FontAwesomeIcon icon={faPlusSquare} size="lg"/></Link> Tel. číslo</div>
          <div className="col-2 text-left">Tarif</div>
          <div className="col-2">Kreditní</div>
          <div className="col-2">&nbsp;</div>
        </div>
        {
          this.props.phoneNumbers.length > 0 ?
          this.props.phoneNumbers.map((phone, i) => { return (
            <PersonaOraclePhoneTableRow entry={phone} key={i} {...this.props} />) }
          )
          : <div className="text-left p-3"><span className="badge badge-warning">Žádná data</span> Kartářka nemá přiřazena žádná telefonní čísla.</div>
        }
      </div>
    );
  }
}

class PersonaOraclePhoneTableRow extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleNumberRemove = this.handleNumberRemove.bind(this);
  }

  render() {
    const entry = this.props.entry;

    if (this.props.oracleDetail == null)
      return (<></>);

    return (
      <div className="row">
        <div className="col-6 py-3"><span className="badge badge-secondary">{entry.stateCode}</span> <span className="font-weight-bold">{entry.phoneNumber}</span></div>
        <div className="col-2">{entry.tariffPrice} Kč</div>
        <div className="col-2">{entry.creditNumber ? 'ANO' : 'NE'}</div>
        <div className="col-2 buttons">
          <a href="/" className="hide-user" data-toggle="modal" data-target={'#removePhoneNumber' + entry.phoneNumberId}><img src="img/cross.png" alt="Odstranit číslo" title="Odstranit číslo"/></a>
        </div>
        <ConfirmDialog id={'removePhoneNumber' + entry.phoneNumberId} title="Odebrat číslo z kartářky" handleSubmit={this.handleNumberRemove}>
          Opravdu chcete odebrat telefonní číslo <span className="badge badge-secondary">{entry.stateCode}</span> <span className="font-weight-bold">{entry.phoneNumber}</span> z kartářky?
        </ConfirmDialog>
      </div>
    );
  }

  handleNumberRemove(event) {
    this.props.removeNumberFromOracle({
      numberId: this.props.entry.phoneNumberId,
      oracleId: this.props.oracleDetail.oracleId
    });
    $("#removePhoneNumber"+this.props.entry.phoneNumberId).modal("hide");
  }
}

const mapDispatchToProps = dispatch => ({
  removeNumberFromOracle: payload =>
    dispatch({ type: REMOVE_NUMBER_FROM_ORACLE, payload })
});

const mapStateToProps = state => {
  return {
    oracleDetail: state.persona.oracleDetail
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(PersonaOraclePhoneTable);

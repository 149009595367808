import React from 'react';
import PropTypes from 'prop-types';

class ImageUploadDialog extends React.PureComponent {

  // https://codepen.io/hartzis/pen/VvNGZP

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: null,
      maxFileSizeError: false
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkFileSize = this.checkFileSize.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  render() {
    const previewStyles = {
      width: this.props.previewWidth || '250px',
      height: this.props.previewHeight || '300px'
    };

    let {imagePreviewUrl} = this.state ? this.state : null;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<div className="preview" style={previewStyles}><img src={imagePreviewUrl} alt="Náhled"/></div>);
    } else {
      $imagePreview = (<div className="preview" style={previewStyles}>Vyberte obrázek k náhledu.</div>);
    }

    return(
      <div className="modal fade imageUploadDialog" id={this.props.id} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{this.props.title || 'Nahrát obrázek'}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetForm}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {this.state.maxFileSizeError &&
              <div class="alert alert-danger" role="alert">
                Překročena maximální velikost souboru {this.props.maxFileSize / 1024} kB.
              </div>
            }
            <form onSubmit={this.handleSubmit}>
              <div className="modal-body">
                <input type="file" onChange={this.handleImageChange} accept={this.props.accept || 'image/png, image/jpeg, image/gif'} multiple={false}/>
              </div>
              <div className="d-flex justify-content-center">{$imagePreview}</div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.resetForm}>Zrušit</button>
                <button type="submit" className="btn btn-primary" disabled={this.state.maxFileSizeError || this.state.file == null}>Potvrdit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.handleImageSubmit(this.state.file);
  }

  handleImageChange(event) {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
      this.checkFileSize();
    }

    reader.readAsDataURL(file);
  }

  checkFileSize() {
    if (this.state.file && this.state.file.size > this.props.maxFileSize)
      this.setState({maxFileSizeError: true});
    else
      this.setState({maxFileSizeError: false});
  }

  resetForm() {
    this.setState({
      file: null,
      imagePreviewUrl: null,
      maxFileSizeError: false
    })
  }
}

ImageUploadDialog.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  handleImageSubmit: PropTypes.func.isRequired,
  previewWidth: PropTypes.string,
  previewHeight: PropTypes.string,
  accept: PropTypes.string,
  maxFileSize: PropTypes.number.isRequired
};

export default ImageUploadDialog;

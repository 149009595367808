import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from '../../common/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'

class PersonaUserOraclesTable extends React.PureComponent {

  render() {
    if (this.props.userDetail === null)
      return (
        <Loader loading={this.props.userDetail === null}/>
      );

    if (this.props.loadingUserOraclesList)
      return (
        <Loader loading={this.props.loadingUserOraclesList}/>
      );

    return (
      <div className="tabulka">
        <div className="row header">
          <div className="col-4"><Link to={'/personas/user/' + this.props.userDetail.userId + '/oracle/new'} className="text-success"><FontAwesomeIcon icon={faPlusSquare} size="lg"/></Link> Uživatel / Kartářka</div>
          <div className="col-3 text-left">Linka / Centrála</div>
          <div className="col-3 text-left">Čísla</div>
        </div>
        {
          this.props.userOraclesList.map((oracle, i) => { return (
            <PersonaUserOraclesTableRow entry={oracle} key={i}/>) }
          )
        }
      </div>
    );
  }
}

class PersonaUserOraclesTableRow extends React.PureComponent {
  render() {
    const entry = this.props.entry;

    return (
      <div className="row">
        <div className="col-12 col-md-4 d-flex">
          <div className="photo">
            <img src={'/api/adm/persona/oracle/' + entry.oracleId + '/avatar'} alt={entry.name} title={entry.name}/>
          </div>
          <div className="name">
            <Link to={'/personas/oracle/' + entry.oracleId + '/detail'} title="Detail kartářky">{entry.name}</Link><br/>
            <span className="grey-light">{entry.title}</span>
          </div>
          <Link to={'/personas/oracle/' + entry.oracleId + '/edit'} className="d-block d-md-none ml-auto"><img src="img/pencil.png" alt="Upravit" title="Upravit"/></Link>
        </div>
        <div className="col-12 col-md-3 text-left">
          Linka <span className="red d-contents">{entry.lineNumber}</span>
        <span className="badge badge-primary ml-2">{entry.exchange.name}</span>
        </div>
        <div className="col-12 col-md-3 text-left">
          {
            entry.phoneNumbers.map((phoneNumber, i) => { return (
              <PersonaUserOraclesTableRowPhone entry={phoneNumber} key={i}/>) }
            )
          }
        </div>
        <div className="d-none d-md-flex col-md-2 buttons">
          <Link to="/" className="display-user"><img src="img/add-user.png" alt="Přidat kartářku" title="Přidat kartářku"/></Link>
          <Link to={'/personas/oracle/' + entry.oracleId + '/edit'} className="hide"><img src="img/pencil.png" alt="Upravit" title="Upravit"/></Link>
        </div>
      </div>
    );
  }
}

class PersonaUserOraclesTableRowPhone extends React.PureComponent {

  render() {
    const entry = this.props.entry;
    return (
      <>{entry.phoneNumber}&nbsp;&nbsp;<span className="red d-contents">{entry.tariffPrice} Kč</span><br/></>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetail: state.persona.userDetail,
    loadingUserOraclesList: state.persona.loadingUserOraclesList,
    userOraclesList: state.persona.userOraclesList
  }
};

export default connect(mapStateToProps)(PersonaUserOraclesTable);

import request from 'superagent';

export default {
  getPageList: () => {
    const req = request
      .get('/api/adm/page/list')
      .withCredentials();
    return req;
  },

  setPageActive: (payload) => {
    const req = request
      .put('/api/adm/page/active')
      .send(payload)
      .withCredentials();
    return req;
  },

  getPage: (payload) => {
    const req = request
      .get('/api/adm/page/' + payload.id)
      .withCredentials();
    return req;
  },

  deletePage: (payload) => {
    const req = request
      .delete('/api/adm/page/' + payload.id)
      .withCredentials();
    return req;
  },

  getPageOracles: (payload) => {
    const req = request
      .get('/api/adm/page/' + payload.id + '/oracles')
      .withCredentials();
    return req;
  },

  updatePageApiPassword: (payload) => {
    const req = request
      .put('/api/adm/page/' + payload.id + '/apipass')
      .send(payload)
      .withCredentials();
    return req;
  },

  updatePage: (payload) => {
    const req = request
      .put('/api/adm/page/' + payload.pageId + '')
      .send(payload)
      .withCredentials();
    return req;
  },

  createPage: (payload) => {
    const req = request
      .post('/api/adm/page')
      .send(payload)
      .withCredentials();
    return req;
  },

  uploadPageAvatar: (payload) => {
    const req = request
      .post('/api/adm/page/' + payload.pageId + '/avatar')
      .attach('file', payload.file)
      .withCredentials();
    return req;
  },

  filterOraclesForSelection: (payload) => {
    const req = request
      .post('/api/adm/page/' + payload.pageId + '/oracles/select')
      .send(payload)
      .withCredentials();
    return req;
  },

  assignOracleToPage: (payload) => {
    const req = request
      .put('/api/adm/page/' + payload.pageId + '/oracles/select/' + payload.oracleId)
      .withCredentials();
    return req;
  },

  removeOracleFromPage: (payload) => {
    const req = request
      .delete('/api/adm/page/' + payload.pageId + '/oracles/select/' + payload.oracleId)
      .withCredentials();
    return req;
  },

  moveOracleUp: (payload) => {
    const req = request
      .post('/api/adm/page/' + payload.pageId + '/oracle/' + payload.oracleId + '/up')
      .withCredentials();
    return req;
  },

  moveOracleDown: (payload) => {
    const req = request
      .post('/api/adm/page/' + payload.pageId + '/oracle/' + payload.oracleId + '/down')
      .withCredentials();
    return req;
  }
}

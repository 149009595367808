import React from 'react';

import PageOracleTableRow from './PageOracleTableRow';

class PageOracleTable extends React.PureComponent {

  render() {
    return(
      this.props.page.oracles.map((oracle, i) => { return (
        <PageOracleTableRow key={i} oracle={oracle}/>
      )})
    );
  }
}

export default PageOracleTable;

import {
  GET_PAGE_LIST,
  GET_PAGE_LIST_OK,
  GET_PAGE_LIST_ERR,
  SET_PAGE_ACTIVE,
  SET_PAGE_ACTIVE_OK,
  SET_PAGE_ACTIVE_ERR,
  GET_PAGE,
  GET_PAGE_OK,
  GET_PAGE_ERR,
  DELETE_PAGE_OK,
  UPDATE_PAGE_OK,
  CREATE_PAGE_OK,
  GET_PAGE_ORACLES,
  GET_PAGE_ORACLES_OK,
  GET_PAGE_ORACLES_ERR,
  UPLOAD_PAGE_AVATAR,
  UPLOAD_PAGE_AVATAR_OK,
  UPLOAD_PAGE_AVATAR_ERR,
  GET_EXCHANGES_LIST,
  GET_EXCHANGES_LIST_OK,
  GET_EXCHANGES_LIST_ERR,
  FILTER_ORACLES_FOR_SELECTION,
  FILTER_ORACLES_FOR_SELECTION_OK,
  FILTER_ORACLES_FOR_SELECTION_ERR,
  ASSIGN_ORACLE_TO_PAGE,
  ASSIGN_ORACLE_TO_PAGE_OK,
  ASSIGN_ORACLE_TO_PAGE_ERR,
  REMOVE_ORACLE_FROM_PAGE_OK,
  MOVE_ORACLE_ON_PAGE_UP,
  MOVE_ORACLE_ON_PAGE_DOWN
} from '../constants/actionTypes';

export default (state = {
  loading: true,
  loadingOraclesList: true,
  pagesList: [],
  pagesCount: 0,
  changingPageActive: false,
  redirectToList: false,
  pageDetail: {},
  pageOracles: [],
  uploadingAvatarPhoto: false,
  exchangesList: [],
  loadingExchangesList: true,
  loadingFilteredOracles: true,
  filteredOracles: [],
  assigningOracleToPage: false,
  redirectToDetail: false,
  movingOracle: false
}, action) => {
  switch (action.type) {
    case GET_PAGE_LIST:
      return {
        ...state,
        loading: true,
        pagesList: [],
        pagesCount: 0,
        redirectToList: false
      };
    case GET_PAGE_LIST_OK:
      return {
        ...state,
        loading: false,
        pagesList: action.payload,
        pagesCount: action.payload ? action.payload.length : 0
      };
    case DELETE_PAGE_OK:
      const deletedPagesList = [];
      state.pagesList.forEach((page) => {
        if (page.pageId !== action.payload.value)
          deletedPagesList.push(page);
      });
      return {
        ...state,
        pagesList: deletedPagesList,
        pagesCount: state.pagesCount - 1
      }
    case GET_PAGE_LIST_ERR:
      return {
        ...state,
        loading: false
      };
    case SET_PAGE_ACTIVE:
      return {
        ...state,
        changingPageActive: true
      };
    case SET_PAGE_ACTIVE_OK:
      const newPagesList = [];
      state.pagesList.forEach((page) => {
        if (page.pageId === action.payload.value1)
          page.active = action.payload.value2;
        newPagesList.push(page);
      });

      return {
        ...state,
        changingPageActive: false,
        pagesList: newPagesList
      };
    case SET_PAGE_ACTIVE_ERR:
      return {
        ...state,
        changingPageActive: false
      };
    case GET_PAGE:
      return {
        ...state,
        loading: true,
        redirectToDetail: false
      }
    case GET_PAGE_OK:
      return {
        ...state,
        loading: false,
        pageDetail: action.payload
      }
    case GET_PAGE_ERR:
      return {
        ...state,
        loading: false
      }
    case UPDATE_PAGE_OK:
    case CREATE_PAGE_OK:
      return {
        ...state,
        redirectToList: true
      }
    case GET_PAGE_ORACLES:
      return {
        ...state,
        loadingOraclesList: true
      }
    case GET_PAGE_ORACLES_OK:
      return {
        ...state,
        loadingOraclesList: false,
        pageOracles: action.payload,
        movingOracles: false
      }
    case GET_PAGE_ORACLES_ERR:
      return {
        ...state,
        loadingOraclesList: false,
        movingOracles: false
      }

    case UPLOAD_PAGE_AVATAR:
      return {
        ...state,
        uploadingAvatarPhoto: true
      }

    case UPLOAD_PAGE_AVATAR_OK:
      return {
        ...state,
        avatarPhotoUpdateDate: new Date().getTime(),
        uploadingAvatarPhoto: false
      }

    case UPLOAD_PAGE_AVATAR_ERR:
      return {
        ...state,
        uploadingAvatarPhoto: false
      }

    case GET_EXCHANGES_LIST:
      return {
        ...state,
        loadingExchangesList: true,
        exchangesList: []
      }
    case GET_EXCHANGES_LIST_OK:
      return {
        ...state,
        loadingExchangesList: false,
        exchangesList: action.payload
      }
    case GET_EXCHANGES_LIST_ERR:
      return {
        ...state,
        loadingExchangesList: false,
        exchangesList: []
      }
    case FILTER_ORACLES_FOR_SELECTION:
      return {
        ...state,
        loadingFilteredOracles: true,
        filteredOracles: []
      }
    case FILTER_ORACLES_FOR_SELECTION_OK:
      return {
        ...state,
        loadingFilteredOracles: false,
        filteredOracles: action.payload
      }
    case FILTER_ORACLES_FOR_SELECTION_ERR:
      return {
        ...state,
        loadingFilteredOracles: false,
        filteredOracles: []
      }

    case ASSIGN_ORACLE_TO_PAGE:
      return {
        ...state,
        assigningOracleToPage: true,
        redirectToDetail: false
      }
    case ASSIGN_ORACLE_TO_PAGE_OK:
      return {
        ...state,
        assigningOracleToPage: false,
        redirectToDetail: true
      }
    case ASSIGN_ORACLE_TO_PAGE_ERR:
      return {
        ...state,
        assigningOracleToPage: false,
        redirectToDetail: false
      }

    case REMOVE_ORACLE_FROM_PAGE_OK:
      const deletedPageOracles = [];
      state.pageOracles.forEach((oracle) => {
        if (action.payload.oracleId !== oracle.oracleId)
          deletedPageOracles.push(oracle);
      });

      return {
        ...state,
        pageOracles: deletedPageOracles
      }

    case MOVE_ORACLE_ON_PAGE_UP:
    case MOVE_ORACLE_ON_PAGE_DOWN:
      return {
        ...state,
        movingOracles: true
      }

    default:
      return state;
  }
};

import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../common/Loader'

import {
  CHANGE_CURRENT_USER_ONCALL_STATUS
} from '../../../constants/actionTypes';

class OracleOncallStatus extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  render() {
    if (!this.props.loadingCurrentUserDetail) {
      const oncall = this.props.oncallStatus ? this.props.oncallStatus === 'ONC' : false;
      const oncallStatusImg = oncall ? 'img/warning-white.png' : 'img/online-white.png';
      const oncallStatusLabel = oncall ? 'Obsazeno' : 'Volno';
      const oncallStatusLabelDetail = oncall ? 'obsazeno, právě telefonuji' : 'volno, čekám na další hovor';
      const oncallStatusButtonLabel = this.props.changingOncallStatus ? 'Pracuji' : oncall ? 'Uvolnit' : 'Obsadit';
      const oncallStatusClass = oncall ? 'offline' : 'online';
      const hasOracles = this.props.currentUserDetail !== null && this.props.currentUserDetail.hasOraclesDefined;

      return(
        <div className={'col-12 col-md-' + this.props.size}>
          {hasOracles && <div className={oncallStatusClass}>
            <img src={oncallStatusImg} alt={oncallStatusLabel} title={oncallStatusLabel}/>
            <div>
              <span className="status-main">{oncallStatusLabel}</span>
              <br></br>
              <span className="status-detail">Současný stav: {oncallStatusLabelDetail}.</span>
            </div>
            <div>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="switch2" checked={oncall} disabled={this.props.changingOncallStatus} onChange={this.handleStatusChange}/>
                <label className="custom-control-label" htmlFor="switch2"></label>
              </div>
              <span className="d-block label">{oncallStatusButtonLabel}</span>
            </div>
          </div>}
          {!hasOracles && <div><span>Nemáte přiřazené žádné kartářky.</span></div>}
        </div>
      );
    }
    else
      return(<Loader loading={this.props.loadingCurrentUserDetail}/>);
  }

  handleStatusChange(event) {
    event.stopPropagation();

    const val = this.props.oncallStatus === 'ONC' ? 'FTC' : 'ONC';
    this.props.changeOncallStatus({oncallStatus: val});
  }
}

const mapDispatchToProps = dispatch => ({
  changeOncallStatus: payload =>
    dispatch({ type: CHANGE_CURRENT_USER_ONCALL_STATUS, payload }),
});

const mapStateToProps = state => {
  return {
    oncallStatus: state.usrdash.oncallStatus,
    changingOncallStatus: state.usrdash.changingOncallStatus,
    currentUserDetail: state.usrdash.currentUserDetail,
    loadingCurrentUserDetail: state.usrdash.loadingCurrentUserDetail
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OracleOncallStatus);

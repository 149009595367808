import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../common/Loader'
import Messages from '../../common/Messages';

import PageCard from './PageCard';
import PageDetail from './PageDetail';


import {
  PAGE_CHANGE,
  GET_PAGES_SUMMARY
} from '../../../constants/actionTypes';

import {
  PAGE_ADMIN_DASHBOARD
} from '../../../constants/pageTypes';

class AdminDashboardView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.props.onPageChange(PAGE_ADMIN_DASHBOARD);
  }

  componentDidMount() {
    this.props.loadPagesSummary();
  }

  render() {
    return(
      <main id="summary-overview" className="col-sm-12 col-lg-9">

        <Messages/>

        <div className="row web-status">
          {
            this.props.loadingPagesSummary ?
              <Loader loading={this.props.loadingPagesSummary}/> :
              this.props.pagesSummary.map((page, i) => { return (
                <PageCard key={i} page={page}/>
              )})
          }
        </div>

        {
          this.props.loadingPagesSummary ?
            <Loader loading={this.props.loadingPagesSummary}/> :
            this.props.pagesSummary.map((page, i) => { return (
              <PageDetail key={i} page={page}/>
            )})
        }

      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onPageChange: payload =>
    dispatch({ type: PAGE_CHANGE, payload }),
  loadPagesSummary: payload =>
    dispatch({ type: GET_PAGES_SUMMARY })
});

const mapStateToProps = state => {
  return {
    loadingPagesSummary: state.admdash.loadingPagesSummary,
    pagesSummary: state.admdash.pagesSummary
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardView);

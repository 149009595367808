import React from 'react';
import { Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import Menu from './menu/Menu'
import ChangePasswordDialog from './common/ChangePasswordDialog'
import ScrollButton from './common/ScrollButton'

import {
  GET_CURRENT_USER_DETAIL,
  OWN_PASSWORD_CHANGE
} from '../constants/actionTypes';

import MyOraclesListView from './dashboard/user/MyOraclesListView';
import AdminDashboardView from './dashboard/admin/AdminDashboardView';
import PersonaListView from './persona/list/PersonaListView';
import PersonaUserDetailView from './persona/detail/PersonaUserDetailView';
import PersonaOracleDetailView from './persona/detail/PersonaOracleDetailView';
import PageListView from './page/list/PageListView';
import PageDetailView from './page/detail/PageDetailView';
import PageAddOracleView from './page/addora/PageAddOracleView';
import AuditListView from './audit/AuditListView';
import PersonaOracleAddNumberView from './persona/addnum/PersonaOracleAddNumberView';
import PersonaOracleAddPageView from './persona/addpage/PersonaOracleAddPageView';
//const MyOraclesListView = React.lazy(() => import('./dashboard/user/MyOraclesListView'));
//const AdminDashboardView = React.lazy(() => import('./dashboard/admin/AdminDashboardView'));
//const PersonaListView = React.lazy(() => import('./persona/list/PersonaListView'));
//const PersonaUserDetailView = React.lazy(() => import('./persona/detail/PersonaUserDetailView'));
//const PageListView = React.lazy(() => import('./page/list/PageListView'))

class Home extends React.Component {

  componentDidMount() {
    this.props.loadCurrentUserDetail();
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-3 sticky-top">
            <Menu/>
            <ChangePasswordDialog id="changeOwnPasswordModal" requireOriginal={true}
              onSubmit={this.handleChangeOwnPassword}/>
          </div>
          <Switch>
            <Route path='/' exact component={(props) => <MyOraclesListView {...props} />}/>
            <Route path='/dashboard' exact component={(props) => <AdminDashboardView {...props} />}/>

            <Route path='/personas' exact component={(props) => <PersonaListView {...props} />}/>
            <Route path='/personas/user/:id/:mode(detail|edit|new)' component={(props) => <PersonaUserDetailView {...props} />}/>
            <Route path='/personas/oracle/:id/:mode(detail|edit)' component={(props) => <PersonaOracleDetailView {...props} />}/>
            <Route path='/personas/oracle/:id/addnum' component={(props) => <PersonaOracleAddNumberView {...props} />}/>
            <Route path='/personas/oracle/:id/addpage' component={(props) => <PersonaOracleAddPageView {...props} />}/>

            <Route path='/personas/user/:id/oracle/:mode(new)' component={(props) => <PersonaOracleDetailView {...props} />}/>

            <Route path='/sites' exact component={(props) => <PageListView {...props} />}/>
            <Route path='/sites/:id/:mode(detail|edit|new)' component={(props) => <PageDetailView {...props} />}/>
            <Route path='/sites/:id/addoracle' component={(props) => <PageAddOracleView {...props} />}/>

            <Route path='/audit' exact component={(props) => <AuditListView {...props} />}/>
          </Switch>
        </div>
        <ScrollButton/>
      </div>
    );
  }

  handleChangeOwnPassword = values => {
    this.props.changeOwnPassword(values);
    $("#changeOwnPasswordModal").modal("hide");
  }
}

const mapDispatchToProps = dispatch => ({
  loadCurrentUserDetail: () =>
    dispatch({ type: GET_CURRENT_USER_DETAIL }),
  changeOwnPassword: (payload) =>
    dispatch({ type: OWN_PASSWORD_CHANGE, payload })
});

const mapStateToProps = state => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faPowerOff } from '@fortawesome/free-solid-svg-icons'

import MenuItem from './MenuItem';
import UserPortrait from './UserPortrait';

import {
  PAGE_USER_DASHBOARD,
  PAGE_ADMIN_DASHBOARD,
  PAGE_PERSONAS_ADMIN,
  PAGE_PAGES_ADMIN,
  PAGE_AUDIT
} from '../../constants/pageTypes';

import {
  LOGOUT
} from '../../constants/actionTypes';

const mapStateToProps = state => {
  return {
    currentPage: state.common.currentPage,
    authenticatedUser: state.common.authenticatedUser
  }
};

class Menu extends React.PureComponent {

  render() {

    const isUSR = this.props.authenticatedUser && this.props.authenticatedUser.authorities[0].authority === 'USR';
    const isADM = this.props.authenticatedUser && this.props.authenticatedUser.authorities[0].authority === 'ADM';

    return (
      <nav id="main-navbar" className="navbar navbar-expand-lg sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          {isADM && <button className="navbar-toggler grey" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">Menu &#9660;</button>}
          <Link to="/" onClick={this.props.logOutUser} className="nav-item nav-link d-lg-none px-3 py-1 d-flex flex-row align-items-center">
            <FontAwesomeIcon icon={faPowerOff} size="2x" className="text-secondary"/>
            <span className="pl-2 fs-5">Odhlášení</span>
          </Link>
        </div>

        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav flex-fill flex-column">
            <UserPortrait/>
            {isADM && <MenuItem itemPage={PAGE_USER_DASHBOARD} to="/">Moje kartářky</MenuItem>}
            {isADM && <MenuItem itemPage={PAGE_ADMIN_DASHBOARD} to="/dashboard">Souhrnný přehled</MenuItem>}
            {isADM && <MenuItem itemPage={PAGE_PERSONAS_ADMIN} to="/personas">Správa kartářek</MenuItem>}
            {isADM && <MenuItem itemPage={PAGE_PAGES_ADMIN} to="/sites">Správa webů</MenuItem>}
            {isADM && <MenuItem itemPage={PAGE_AUDIT} to="/audit">Události</MenuItem>}
          </div>
        </div>
      </nav>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logOutUser: () =>
    dispatch({ type: LOGOUT })
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

import React from 'react';
import { connect } from 'react-redux';

class PageCard extends React.PureComponent {

  render() {
    const page = this.props.page;
    const online = page.status && page.status === 'ONL';
    const icon = online ? 'img/online-white.png' : 'img/warning-white.png';
    const iconTitle = online ? 'Online' : 'Offline';
    const suffix = online ? 'kartářek online' : 'web nedostupný';
    const divClass = online ? 'online' : 'offline';

    return(
      <div className="col-12 col-md-6 col-lg-4">
        <div className={divClass}>
          <img src={icon} alt={iconTitle} title={iconTitle}/>
          <span className="count">{page.oraclesOnline}<span className="grey-medium">/</span>{page.oraclesTotal}</span> {suffix}
          <span className="web"> {page.domain}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    changingPageOracleActive: state.admdash.changingPageOracleActive,
    changingPageOracleOverride: state.admdash.changingPageOracleOverride
  }
};

export default connect(mapStateToProps)(PageCard);

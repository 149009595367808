import {
  GET_PERSONA_LIST_ERR,
  GET_PERSONA_USER_ERR,
  GET_PERSONA_ORACLE_ERR,
  GET_PERSONA_USER_ORACLES_LIST_ERR,
  UPDATE_PERSONA_USER_ERR,
  UPDATE_PERSONA_ORACLE_ERR,
  DELETE_PERSONA_ORACLE_ERR,
  DELETE_PERSONA_USER_ERR,
  CREATE_PERSONA_USER_ERR,
  CREATE_PERSONA_ORACLE_ERR,
  AUTHENTICATED_USER_LOAD_ERR,
  GET_PAGE_LIST_ERR,
  GET_CURRENT_USER_DETAIL_ERR,
  OWN_PASSWORD_CHANGE_ERR,
  CHANGE_CURRENT_USER_ONLINE_STATUS_ERR,
  CHANGE_CURRENT_USER_ONCALL_STATUS_ERR,
  LOAD_CURRENT_USER_ORACLES_ERR,
  GET_PAGES_SUMMARY_ERR,
  SET_PAGE_ACTIVE_ERR,
  CHANGE_PAGE_API_PASSWORD_ERR,
  UPDATE_PAGE_ERR,
  CREATE_PAGE_ERR,
  DELETE_PAGE_ERR,
  GET_PAGE_ORACLES_ERR,
  SET_PAGE_ORACLE_ACTIVE_ERR,
  SET_PAGE_ORACLE_OVERRIDE_ERR,
  GET_EXCHANGES_LIST_ERR,
  PERSONA_USER_PASSWORD_CHANGE_ERR,
  UPLOAD_ORACLE_AVATAR_ERR,
  UPLOAD_USER_AVATAR_ERR,
  UPLOAD_PAGE_AVATAR_ERR,
  FILTER_ORACLES_FOR_SELECTION_ERR,
  ASSIGN_ORACLE_TO_PAGE_ERR,
  REMOVE_ORACLE_FROM_PAGE_ERR,
  FILTER_PAGES_FOR_SELECTION_ERR,
  FILTER_NUMBERS_FOR_SELECTION_ERR,
  ASSIGN_NUMBER_TO_ORACLE_ERR,
  REMOVE_NUMBER_FROM_ORACLE_ERR,
  MOVE_ORACLE_ON_PAGE_UP_ERR,
  MOVE_ORACLE_ON_PAGE_DOWN_ERR,

  AUTH_SUCCESS,
  UPDATE_PERSONA_USER_OK,
  UPDATE_PERSONA_ORACLE_OK,
  DELETE_PERSONA_ORACLE_OK,
  DELETE_PERSONA_USER_OK,
  UPDATE_PAGE_OK,
  DELETE_PAGE_OK,
  CREATE_PERSONA_USER_OK,
  CREATE_PERSONA_ORACLE_OK,
  OWN_PASSWORD_CHANGE_OK,
  CHANGE_PAGE_API_PASSWORD_OK,
  PERSONA_USER_PASSWORD_CHANGE_OK,
  CREATE_PAGE_OK,
  UPLOAD_ORACLE_AVATAR_OK,
  UPLOAD_USER_AVATAR_OK,
  UPLOAD_PAGE_AVATAR_OK,

  CLEAR_MESSAGES
} from '../constants/actionTypes';

export default (state = {
  messages: []
}, action) => {
  switch (action.type) {
    case CLEAR_MESSAGES:
    return {
      ...state,
      messages: []
    };

    case GET_PERSONA_LIST_ERR:
    case GET_PERSONA_USER_ERR:
    case GET_PERSONA_USER_ORACLES_LIST_ERR:
    case UPDATE_PERSONA_USER_ERR:
    case UPDATE_PERSONA_ORACLE_ERR:
    case DELETE_PERSONA_ORACLE_ERR:
    case DELETE_PERSONA_USER_ERR:
    case CREATE_PERSONA_USER_ERR:
    case CREATE_PERSONA_ORACLE_ERR:
    case AUTHENTICATED_USER_LOAD_ERR:
    case OWN_PASSWORD_CHANGE_ERR:
    case GET_PAGE_LIST_ERR:
    case GET_CURRENT_USER_DETAIL_ERR:
    case CHANGE_CURRENT_USER_ONLINE_STATUS_ERR:
    case CHANGE_CURRENT_USER_ONCALL_STATUS_ERR:
    case LOAD_CURRENT_USER_ORACLES_ERR:
    case GET_PAGES_SUMMARY_ERR:
    case SET_PAGE_ACTIVE_ERR:
    case CHANGE_PAGE_API_PASSWORD_ERR:
    case UPDATE_PAGE_ERR:
    case CREATE_PAGE_ERR:
    case DELETE_PAGE_ERR:
    case GET_PAGE_ORACLES_ERR:
    case SET_PAGE_ORACLE_ACTIVE_ERR:
    case SET_PAGE_ORACLE_OVERRIDE_ERR:
    case GET_PERSONA_ORACLE_ERR:
    case GET_EXCHANGES_LIST_ERR:
    case PERSONA_USER_PASSWORD_CHANGE_ERR:
    case UPLOAD_ORACLE_AVATAR_ERR:
    case UPLOAD_USER_AVATAR_ERR:
    case UPLOAD_PAGE_AVATAR_ERR:
    case FILTER_ORACLES_FOR_SELECTION_ERR:
    case ASSIGN_ORACLE_TO_PAGE_ERR:
    case REMOVE_ORACLE_FROM_PAGE_ERR:
    case FILTER_PAGES_FOR_SELECTION_ERR:
    case FILTER_NUMBERS_FOR_SELECTION_ERR:
    case ASSIGN_NUMBER_TO_ORACLE_ERR:
    case REMOVE_NUMBER_FROM_ORACLE_ERR:
    case MOVE_ORACLE_ON_PAGE_UP_ERR:
    case MOVE_ORACLE_ON_PAGE_DOWN_ERR:

      let messages = [];
      if (action.payload && action.payload.response && action.payload.response.text) {
        const response = JSON.parse(action.payload.response.text);
        if (response.message) {
          messages.push({
            type: 'error',
            content: response.message
          });
        }
        if (response.errors)
          response.errors.map(err => messages.push({type: 'error', content: err.defaultMessage}));
      }
      else {
        messages.push({type: 'error', content: 'Neznámá chyba komunikace s API.'})
      }

      return {
        ...state,
        messages: state.messages.concat(messages)
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Byli jste úspěšně přihlášení do systému.'
        }])
      };

    case UPDATE_PERSONA_USER_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Uživatel ' + action.payload.email + ' úspěšně aktualizován.'
        }])
      };

    case UPDATE_PERSONA_ORACLE_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Kartářka ' + action.payload.name + ' úspěšně aktualizována.'
        }])
      };

    case DELETE_PERSONA_ORACLE_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Kartářka úspěšně smazána.'
        }])
      };

    case DELETE_PERSONA_USER_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Uživatel úspěšně smazán.'
        }])
      };

    case UPDATE_PAGE_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Stránka ' + action.payload.name + ' úspěšně aktualizována.'
        }])
      };

    case DELETE_PAGE_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Stránka úspěšně smazána.'
        }])
      };

    case CREATE_PAGE_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Stránka ' + action.payload.name + ' úspěšně založena.'
        }])
      };

    case CREATE_PERSONA_USER_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Uživatel ' + action.payload.email + ' úspěšně založen.'
        }])
      };

    case CREATE_PERSONA_ORACLE_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Kartářka ' + action.payload.name + ' úspěšně založena.'
        }])
      };

    case OWN_PASSWORD_CHANGE_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Vaše heslo bylo úspěšně změněno.'
        }])
      };

    case CHANGE_PAGE_API_PASSWORD_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Heslo API pro stránku bylo úspěšně změněno.'
        }])
      };

    case PERSONA_USER_PASSWORD_CHANGE_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Heslo bylo úspěšně změněno.'
        }])
      };

    case UPLOAD_ORACLE_AVATAR_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Fotka kartářky byla úspěšně změněna.'
        }])
      };

    case UPLOAD_USER_AVATAR_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Fotka uživatele byla úspěšně změněna.'
        }])
      };

    case UPLOAD_PAGE_AVATAR_OK:
      return {
        ...state,
        messages: state.messages.concat([{
          type: 'info',
          content: 'Fotka stránky byla úspěšně změněna.'
        }])
      };

    default:
      return state;
  }
};
